// actions constants

export const DATA_LAYER_PUSH_ACTION = 'data-layer/PUSH_ACTION';

// plain actions

export function dataLayerPushAction(payload) {
  return {
    type: DATA_LAYER_PUSH_ACTION,
    payload,
  };
}
