import { connect } from 'react-redux';
import MainMenu from './presentation.js';
import { toggleMenu, toggleSubmenu, toggleSignInUpModal } from '../../redux/modules/ui';
import { selectMenuData } from './selectMenuData.js';

export default connect((state) => {
  const {
    ui: { menuOpen, submenuOpen },
    content: { userData, allowedToSeeKaffeeklatsch, notificationCount },
    router,
  } = state;

  const location = router ? router.location : undefined;

  return {
    menuOpen,
    submenuOpen,
    menuData: selectMenuData(state),
    userData,
    allowedToSeeKaffeeklatsch,
    notificationCount,
    location,
  };
}, {
  toggleMenu,
  toggleSubmenu,
  toggleSignInUpModal,
})(MainMenu);
