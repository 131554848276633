import { connect } from 'react-redux';

import { toggleSignInUpModal } from '../../../redux/modules/ui';

import SingleUserComment from './presentation';

export default connect(({ content: { userData } }) => ({
  userData,
}), {
  toggleSignInUpModal,
})(SingleUserComment);
