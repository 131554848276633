/* globals pintrk */
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { history } from '../redux/store';
import setTimeouts from './setTimeouts';

// const GA_ID = 'UA-75431036-1' // Old Google Universal Analytics ID
// const GA_ID = 'G-REPMTBN7CE'; // GA4 Measurement ID - Live
// const GA_ID = 'G-H4MKZXBLF6'; // GA4 Measurement ID - Test
const GA_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
const FB_ID = process.env.REACT_APP_FB_TRACKER_ID;
// const FB2_ID = '736616713447433';
const PINTEREST_ID = process.env.REACT_APP_PINTEREST_TRACKER_ID;
// const ADFORM_ID = process.env.REACT_APP_ADFORM_TRACKER_ID;

const eventQueueGoogleAnalytics = [];
const eventQueueAdform = [];
const eventQueuePinterest = [];
const eventQueueFacebook = [];

const initializedTrackersMap = {};
let currentGtmConsentState = {
  facebookConsent: false,
  adformConsent: false,
  matomoConsent: false,
  youtubeVideoConsent: false,
  ga4Consent: false,
  pinterestConsent: false,
};

function initTrackers(gtmConsentState) {
  if (gtmConsentState.facebookConsent && !initializedTrackersMap.facebookPixel) {
    ReactPixel.init(FB_ID);
    initializedTrackersMap.facebookPixel = true;
  }
  if (gtmConsentState.ga4Consent && !initializedTrackersMap.googleAnalytics) {
    ReactGA.initialize(GA_ID, { gtagOptions: { debug_mode: process.env.NODE_ENV === 'development' } });
    initializedTrackersMap.googleAnalytics = true;
  }
  if (gtmConsentState.adformConsent && !initializedTrackersMap.adform) {
    window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
    window._adftrack.push({
      HttpHost: 'track.adform.net',
      pm: 2439907,
    });

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js';
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
    initializedTrackersMap.adform = true;
  }
  if (gtmConsentState.pinterestConsent && !initializedTrackersMap.pinterestAnalytics) {
    pintrk('load', PINTEREST_ID);
    initializedTrackersMap.pinterestAnalytics = true;
  }
  if (window._paq && window._paq.push) {
    if (currentGtmConsentState.matomoConsent) {
      window._paq.push(['setCookieConsentGiven']);
    } else {
      window._paq.push(['forgetCookieConsentGiven']);
    }
  }
  if (!initializedTrackersMap.trackHistory) {
    trackPageChangeEvents(window.location);
    history.listen(trackPageChangeEvents);
    initializedTrackersMap.trackHistory = true;
  }
}

export function updateTracker(gtmConsentState) {
  const oldConsentState = currentGtmConsentState;
  currentGtmConsentState = { ...currentGtmConsentState, ...gtmConsentState };
  if (Object.keys(oldConsentState).some(key => !!oldConsentState[key] && !currentGtmConsentState[key])) {
    window.location.reload();
  };

  initTrackers(currentGtmConsentState);
}

export function getCurrentGtmConsentState() {
  return currentGtmConsentState;
}

function trackGoogleAnalyticsEvent(category, action, label = 'backmomente', options = {}) {
  const event = { category, action, label, ...options };
  ReactGA.event(event);
}

function trackMatomoEvent(category, action, track) {
  if (currentGtmConsentState.matomoConsent) window._paq.push(['trackEvent', category, action]);
}

export function trackPageView() {
  function trackGoogleAnalyticsEvt({ pathname, search } = window.location) {
    ReactGA.pageview(pathname + search);
  };
  function trackPinterestEvt() {
    if (pintrk) pintrk('page');
    Tracker.trackPinterestEvent('pagevisit');
  };
  function trackFacebookEvent() {
    ReactPixel.pageView();
  };
  function trackAdformEvent() {
    ReactPixel.pageView();
  };
  function trackMatomoPageView() {
    window._paq.push(['setDocumentTitle', document.title]);
    window._paq.push(['trackPageView']);
  }

  if (currentGtmConsentState.ga4Consent) {
    trackGoogleAnalyticsEvt();
  } else {
    eventQueueGoogleAnalytics.push(trackGoogleAnalyticsEvt);
  }

  if (currentGtmConsentState.adformConsent) {
    trackAdformEvent();
  } else {
    eventQueueAdform.push(trackAdformEvent);
  }

  if (currentGtmConsentState.facebookConsent) {
    trackFacebookEvent();
  } else {
    eventQueueFacebook.push(trackFacebookEvent);
  }

  if (currentGtmConsentState.pinterestConsent) {
    trackPinterestEvt();
  } else {
    eventQueuePinterest.push(trackPinterestEvt);
  }

  if (currentGtmConsentState.matomoConsent) {
    trackMatomoPageView();
    // TODO think about event queue without consent
  }
}

export function trackEvent(category, action, label = 'backmomente', options = {}) {
  if (currentGtmConsentState.ga4Consent) trackGoogleAnalyticsEvent(category, action, label, options);
  else eventQueueGoogleAnalytics.push(trackGoogleAnalyticsEvent.bind(null, category, action, label));

  trackMatomoEvent(category, action);
}

export function trackCustomFbEvent(event, data) {
  function trackFacebookPixelEvt() {
    ReactPixel.trackCustom(event, data);
  };
  if (currentGtmConsentState.facebookConsent) {
    trackFacebookPixelEvt();
  } else {
    eventQueueFacebook.push(trackFacebookPixelEvt);
  }
}

export function trackFbEvent(event, data) {
  function trackFacebookEvt() {
    ReactPixel.track(event, data);
  };
  if (currentGtmConsentState.facebookConsent) {
    trackFacebookEvt();
  } else {
    eventQueueFacebook.push(trackFacebookEvt);
  }
}

export function trackPinterestEvent(event, data) {
  function trackPinterstEvt() {
    if (pintrk) pintrk('track', event, data);
  };
  if (currentGtmConsentState.pinterestConsent) trackPinterstEvt();
  else eventQueuePinterest.push(trackPinterstEvt);
}

let clearRetentionTimeouts = () => {};
function trackRetentionTimes() {
  clearRetentionTimeouts();

  clearRetentionTimeouts = setTimeouts((ms) => {
    const s = ms / 1000;
    if (s < 60) return trackEvent('Verweilen', `Verweildauer > ${s} Sek.`);
    trackEvent('Verweilen', `Verweildauer > ${s / 60} Min.`, 'backmomente', { nonInteraction: true });
  }, [15, 30, 60, 2 * 60, 5 * 60].map(t => t * 1000));
}

let oldPath = '';
function trackPageChangeEvents({ pathname }) {
  if (oldPath === pathname) return;

  trackPageView();
  trackRetentionTimes();

  function trackFacebookEvt() {
    if (pathname.indexOf('/rezept/') !== -1 || pathname.indexOf('/artikel/') !== -1) {
      ReactPixel.track('ViewContent');
    }
    if (pathname.indexOf('/kontakt') !== -1) ReactPixel.track('Contact');
  };

  if (currentGtmConsentState.facebookConsent) {
    trackFacebookEvt();
  } else {
    eventQueueFacebook.push(trackFacebookEvt);
  }

  oldPath = pathname;
}

class Tracker {
  static init() {
    return Tracker;
  }

  /**
   * @deprecated import and use trackPageView function instead
   */
  static trackPageView() {
    trackPageView();
  }

  /**
   * @deprecated import and use trackEvent function instead
   */
  static trackEvent(category, action, label = 'backmomente', options = {}) {
    trackEvent(category, action, label, options);
  }

  /**
   * @deprecated import and use trackCustomFbEvent function instead
   */
  static trackCustomFbEvent(event, data) {
    trackCustomFbEvent(event, data);
  }

  /**
   * @deprecated import and use trackFbEvent function instead
   */
  static trackFbEvent(event, data) {
    trackFbEvent(event, data);
  }

  /**
   * @deprecated import and use trackPinterestEvent function instead
   */
  static trackPinterestEvent(event, data) {
    trackPinterestEvent(event, data);
  }
}

export default Tracker;
