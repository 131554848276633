const SET_COMMENT_OVERLAY_OPEN = 'comment-overlay/set-comment-overlay-open';
const SET_COMMENT_RECIPE = 'comment-overlay/set-comment-recipe';
const SET_COMMENT_AFTER_VOTE_OR_COMMENT_CALLBACK = 'comment-overlay/set-comment-after-vote-or-comment-callback';

const initialState = {
  commentOverlayOpen: false,
  recipe: null,
  callbackOnVoteOrComment: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENT_OVERLAY_OPEN: {
      const { commentOverlayOpen } = action;
      return Object.assign({}, state, { commentOverlayOpen });
    }
    case SET_COMMENT_RECIPE: {
      const { recipe } = action;
      return Object.assign({}, state, { recipe });
    }
    case SET_COMMENT_AFTER_VOTE_OR_COMMENT_CALLBACK: {
      const { callbackOnVoteOrComment } = action;
      return Object.assign({}, state, { callbackOnVoteOrComment });
    }
    default:
      return state;
  }
};

export const setCommentOverlayOpen = commentOverlayOpen => ({
  type: SET_COMMENT_OVERLAY_OPEN,
  commentOverlayOpen,
});

export const setCommentRecipe = recipe => ({
  type: SET_COMMENT_RECIPE,
  recipe,
});

export const setCommentRecipeAfterVoteOrCommentCallback = callbackOnVoteOrComment => ({
  type: SET_COMMENT_AFTER_VOTE_OR_COMMENT_CALLBACK,
  callbackOnVoteOrComment,
});
