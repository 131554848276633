import { connect } from 'react-redux';
import Presentation from './presentation.js';

export default connect(({
  content: {
    footerMenuData,
    socialMediaLinks,
    brandLinks,
  },
}) => ({
  footerMenuData,
  socialMediaLinks,
  brandLinks,
}))(Presentation);
