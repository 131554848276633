import { connect } from 'react-redux';
import Presentation from './presentation.js';
import { toggleMenu, toggleSignInUpModal } from '../../redux/modules/ui';

// Assuming the routing information is stored under `routing` key in the state
const mapStateToProps = (state) => ({
  userData: state.content.userData,
  notificationCount: state.content.notificationCount,
  location: state.routing?.location, // Adjust this to match your state structure
});

export default connect(mapStateToProps, {
  toggleMenu,
  toggleSignInUpModal,
})(Presentation);
