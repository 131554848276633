import React from 'react';
import './Styles.css';

function PageLoadable() {
  return (
    <div className="PageLoadable" />
  );
}

export default PageLoadable;
