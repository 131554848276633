import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CustomSelect from '../../components/CustomSelect';
import ImageUploader from '../../components/ImageUploader';
import './Styles.css';
import Page from '../Page';

import heroDesktop from '../../images/activation-campaign/AURO_Header_Desktop_2202x908.webp';
import heroMobile from '../../images/activation-campaign/AURO_Header_Mobile_640x896.webp';

class ActivationCampaign extends Component {
  state = {
    viewportWidth: window.innerWidth,
    firstname: '',
    lastname: '',
    email: '',
    selectedMehl: '',
    termsAccepted: false,
    actionAccepted: false,
    selectedImage: null,
    errors: {},
    isSubmitting: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this._onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize);
  }

  _onResize = () => {
    this.setState({ viewportWidth: window.innerWidth });
  }

  validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!this.state.firstname) {
      formIsValid = false;
      errors.firstname = 'Vorname ist erforderlich';
    }

    if (!this.state.lastname) {
      formIsValid = false;
      errors.lastname = 'Nachname ist erforderlich';
    }

    if (!this.state.email) {
      formIsValid = false;
      errors.email = 'E-Mail ist erforderlich';
    } else if (
      !/^[\w-]+(\.[\w]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(this.state.email)
    ) {
      formIsValid = false;
      errors.email = 'E-Mail ist nicht gültig';
    }

    if (!this.state.termsAccepted) {
      formIsValid = false;
      errors.termsAccepted = 'Sie müssen die Bedingungen akzeptieren';
    }

    if (!this.state.selectedMehl) {
      formIsValid = false;
      errors.selectedMehl = 'Bitte wählen Sie einen Mehltyp aus';
    }

    if (!this.state.selectedImage) {
      formIsValid = false;
      errors.selectedImage = 'Bitte laden Sie ein Bild hoch';
    }

    this.setState({ errors });
    return formIsValid;
  }

  _onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });

    if (this.validateForm()) {
      const payload = {
        ProductName: this.state.selectedMehl,
        FirstName: this.state.firstname,
        LastName: this.state.lastname,
        Email: this.state.email,
        Terms: this.state.termsAccepted,
        PrivacyConsent: this.state.termsAccepted,
        Receipt: this.state.selectedImage,
      };

      try {
        const response = await fetch('https://www.promoapi.de/llb-api/application/rest/add-participation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Api-Token': 'LIVE_UQ4CJ91ZHCW16I4U51D0UIYBCEYQEJKG',
          },
          body: JSON.stringify(payload),
        });

        const result = await response.json();

        if (response.ok) {
          if (this.state.actionAccepted) {
            // Add to newsletter here
          }
          localStorage.setItem('participationData', JSON.stringify(result.data));
          this.props.navigate('/lebeliebebacke-ergebnis#ergebnis');
        } else {
          console.error('Submission Failed: ', result);
        }
      } catch (error) {
        console.error('There was an error submitting the form: ', error);
      }
      this.setState({ isSubmitting: false });
    } else {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const { viewportWidth } = this.state;
    const imageSrc = viewportWidth < 1080 ? heroMobile : heroDesktop;

    return (
      <Page className="ActivationCampaign">
        <Helmet>
          <title>Lebeliebebacke</title>
        </Helmet>
        <section className="ActivationCampaign__hero">
          <img src={imageSrc} alt="Campaign Hero" />
        </section>
        <section className="ActivationCampaign__description">
          <h1>Selbst der größte spaß geht leider mal zu Ende...</h1>
          <p>
            ... und auch unser Gewinnspiel ist seit dem 01.04.2024 vorbei.<br /><br />
            Aber das Nächste kommt bestimmt schon bald!<br /><br />
            Besuche uns einfach regelmäßig auf: <a href="www.Backmomente.de">www.Backmomente.de</a>
          </p>
        </section>
      </Page>
    );
  }
}

export default ActivationCampaign;
