import React from 'react';
import './Styles.css';

import UserCrownLevel from '../UserCrownLevel';

function RecipeAuthor({ author, crown }) {
  return (
    <div className="RecipeAuthor">
      <span className="RecipeAuthor--von">von&nbsp;</span>
      <span className="RecipeAuthor--name">{author}</span>
      <UserCrownLevel count={crown} />
    </div>
  );
}

export default RecipeAuthor;
