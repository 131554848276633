import classNames from 'classnames';
import React, { memo } from 'react';

import './Styles.css';

export const HIGHLIGHTED_BUTTON_CLASS_NAME = 'highlighted-button';

function HighlightedButton({ children, className, ...props }) {
  return (
    <span className={ classNames(HIGHLIGHTED_BUTTON_CLASS_NAME, className) } { ...props }>{ children }</span>
  );
}

export default memo(HighlightedButton);
