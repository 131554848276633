import React, { Component } from 'react';
import './Styles.css';
import uploadIcon from '../../images/activation-campaign/icons/Icon-Add.svg';

class ImageUploader extends Component {
  state = {
    imageName: 'Kassenbon hochladen',
    encodedImage: null,
  }

  getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Save the image name in the state
      this.setState({ imageName: file.name });

      // Convert the image to base64 and save it in the state
      this.getBase64(file).then(encodedImage => {
        this.setState({ encodedImage });

        // Notify the parent component (if the prop exists) with the selected image file

        if (this.props.onEncodedImage) {
          this.props.onEncodedImage(encodedImage); // Sending encodedImage to the parent
        }
      });
    }
  };

  render() {
    return (
      <div className="custom-upload-input">
        <label htmlFor="customFileUpload">
          {this.state.imageName}
          <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
        </label>
        <input
          id="customFileUpload"
          type="file"
          accept="image/*"
          onChange={this.handleImageChange}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

export default ImageUploader;
