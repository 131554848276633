import React, { Component } from 'react';

import './Styles.css';

export default class InputTooltip extends Component {
  render() {
    const {
      message,
      style,
      shown,
      setShown,
    } = this.props;

    const containerStyle = {
      display: shown ? 'block' : 'none',
    };

    return (
      <div
        style={containerStyle}
        className="InputTooltip--container">
        <div
          style={style}
          className={'InputTooltip--message'}
        >
          <div
            className="InputTooltip--close"
            onClick={e => setShown(false)}
          ></div>
          {message}
        </div>
      </div>
    );
  }
}
