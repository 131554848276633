import React from 'react';
import './Styles.css';

function Layout({ children }) {
  return (
    <div className="Layout">
      {children}
    </div>
  );
}

export default Layout;
