import Dexie from 'dexie';

const CACHE_EXPIRY_MS = 300 * 1000;

const db = new Dexie('backmomente');
db.version(1).stores({
  cache: 'cacheKey, created',
});

const Cache = {
  get(_key) {
    return new Promise((resolve, reject) => db.cache
      .where('created')
      .below(new Date(Date.now() - CACHE_EXPIRY_MS))
      .delete()
      .then((_result) => {
        db.cache
          .where('cacheKey')
          .equals(_key)
          .toArray()
          .then((_data) => {
            if (_data.length > 0) {
              resolve(_data[0].cacheData);
            } else {
              reject(new Error('No cache hit'));
            }
          })
          .catch((_reason) => {
            reject(_reason);
          });
      })
      .catch((_reason) => {
        reject(_reason);
      }),
    );
  },

  set(_key, _data) {
    db.cache.put({
      cacheKey: _key,
      cacheData: _data,
      created: new Date(),
    });
  },

  clear(_olderThan = false) {
    if (!_olderThan) {
      // empty cache
      db.cache
        .delete()
        .then((_result) => {
          // console.log('Cache cleared', _result)
        })
        .catch((_reason) => {
          // console.log('Cache clearing failed', _reason)
        });
    } else {
      // fetch all older than
      db.cache
        .where('created')
        .below(_olderThan)
        .delete()
        .then((_result) => {
          // console.log('Cache cleared', _result)
        })
        .catch((_reason) => {
          // console.log('Cache clearing failed', _reason)
        });
    }
  },
};

export default Cache;
