import Cache from './Cache';

import store from '../../redux/store';
import {
  setFilters,
  setChannels,
  setHomepageData,
  setInitData,
  authUser,
  authInProgress,
  authFinished,
} from '../../redux/modules/content';

import Bootstrap from '../Bootstrap';

const STAGING_API_URL = 'https://live.backmomente.de/wp-json/';
const LIVE_API_URL = 'https://live.backmomente.de/wp-json/';
const LOCAL_API_URL = 'https://live.backmomente.de/wp-json/';
const JUICER_API_URL = 'https://www.juicer.io/api/feeds/backmomente-de';
// const NODE_SERVER_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'http://localhost:3001';

const API = {
  token: null,
  rawFetch(_url, _cache = true) {
    const headers = new Headers();
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return Cache.get(_url)
      .catch(() => fetch(_url, {
        headers,
      })
        .then((_response) => _response.json()
          .then((_data) => {
            if (_cache) Cache.set(_url, _data);
            return _data;
          })));

    /*
    return fetch(_url)
      .then(function(_response) {
        return _response.json()
          .then(function(_data) {
            // if(_cache) Cache.set(_url, _data);
            return _data;
          });
      });
      */
  },

  fetch(_endpoint) {
    return this.constructedFetch(_endpoint);
  },

  getToken(username, password) {
    let url;

    if (process.env.REACT_APP_BUILD_ENV === 'live') {
      url = `${LIVE_API_URL}jwt-auth/v1/token`;
    } else if (process.env.REACT_APP_BUILD_ENV === 'staging') {
      url = `${STAGING_API_URL}jwt-auth/v1/token`;
    } else {
      url = `${LOCAL_API_URL}jwt-auth/v1/token`;
    }

    const bodyData = {
      username: 'sebastianflock',
      password: 'R4zGr1rnW04@E%hl',
    };

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.token) {
          this.token = data.token; // Store the token
        }
        return data;
      });
  },

  constructedFetch(_endpoint = '', _cache = true) {
    let url;
    if (process.env.REACT_APP_BUILD_ENV === 'live') {
      url = `${LIVE_API_URL}${_endpoint}`;
    } else if (process.env.REACT_APP_BUILD_ENV === 'staging') {
      url = `${STAGING_API_URL}${_endpoint}`;
    } else {
      url = `${LOCAL_API_URL}${_endpoint}`;
    }

    return this.rawFetch(url, _cache);
  },

  constructedPost(_endpoint, _postdata) {
    let url;
    if (process.env.REACT_APP_BUILD_ENV === 'live') {
      url = `${LIVE_API_URL}${_endpoint}`;
    } else if (process.env.REACT_APP_BUILD_ENV === 'staging') {
      url = `${STAGING_API_URL}${_endpoint}`;
    } else {
      url = `${LOCAL_API_URL}${_endpoint}`;
    }

    const data = new FormData();
    for (let i = 0; i < Object.keys(_postdata).length; i++) {
      const __key = Object.keys(_postdata)[i];
      const __data = _postdata[__key];
      if (__data !== undefined) {
        data.append(__key, __data);
      }
    }
    // data.append("payload", JSON.stringify(_data));

    return fetch(url, {
      method: 'post',
      body: data,
    }).then((_response) => _response.json()
      .then((_data) => _data));
  },

  addSweetGreeting({ text: sweetGreetingsText }) {
    return this.constructedPost('kh/v1/addSweetGreeting', {
      value: sweetGreetingsText,
      auth: store.getState().content.userData.authToken,
    });
  },

  addUserKey({ key, value }) {
    return this.constructedPost('kh/v1/addUserKey', {
      key,
      value,
      auth: store.getState().content.userData.authToken,
    });
  },

  deleteUserKey({ key }) {
    return this.constructedPost('kh/v1/deleteUserKey', {
      key,
      auth: store.getState().content.userData.authToken,
    });
  },

  getUserKey({ key }) {
    return this.constructedPost('kh/v1/getUserKey', {
      key,
      auth: store.getState().content.userData.authToken,
    });
  },

  fetchJuicerFeed({ perPage, page }) {
    return this.rawFetch(`${JUICER_API_URL}?per=${perPage}&page=${page + 1}`);
  },

  fetchRecipeBySlug(_slug, _cache = true) {
    return this.constructedFetch(`wp/v2/rezept?slug=${encodeURIComponent(_slug)}`, _cache).then((_response) => _response);
  },

  fetchInvitationTokenUsername(token) {
    return this.constructedFetch(`kh/v1/getInvitationTokenUsername?token=${token}`).then((_response) => _response);
  },

  // order: desc
  // orderby: rating, date
  // filter: author, kategorie, time, difficulty, flour
  fetchRecipes({ searchTerm, noCache, filterIds, orderBy, authorIn, authorNotIn, postIn, perPage, offset }) {
    const cache = !noCache;
    let searchTermQuery = '';
    if (searchTerm) {
      searchTermQuery = `search=${encodeURIComponent(searchTerm)}&`;
    }
    let _filterQuery = `filter=${encodeURIComponent(JSON.stringify({}))}&`;
    if (filterIds) {
      _filterQuery = `filter=${encodeURIComponent(JSON.stringify(filterIds))}&`;
    }
    let _orderByQuery = '';
    if (orderBy) {
      _orderByQuery = `orderby=${encodeURIComponent(orderBy)}&`;
    }
    let _authorInQuery = '';
    if (authorIn) {
      _authorInQuery = `author_in=${encodeURIComponent(JSON.stringify(authorIn))}&`;
    }
    let _authorNotInQuery = '';
    if (authorNotIn) {
      _authorNotInQuery = `author_not_in=${encodeURIComponent(JSON.stringify(authorNotIn))}&`;
    }
    let _postInQuery = '';
    if (postIn) {
      _postInQuery = `post_in=${encodeURIComponent(JSON.stringify(postIn))}&`;
    }
    let _perPageQuery = '';
    if (perPage) {
      _perPageQuery = `per_page=${encodeURIComponent(perPage)}&`;
    }
    let _offsetQuery = '';
    if (offset) {
      _offsetQuery = `offset=${encodeURIComponent(offset)}&`;
    }
    return this.constructedFetch(`kh/v1/getRecipes?${searchTermQuery}${_perPageQuery}${_offsetQuery}${_orderByQuery}${_filterQuery}${_authorInQuery}${_authorNotInQuery}${_postInQuery}`, cache).then((_response) => _response);
  },

  searchRecipes(searchTerm, perPage = 30, offset = 0, filterIds) {
    let _filterQuery = `filter=${encodeURIComponent(JSON.stringify({}))}&`;
    if (filterIds) {
      _filterQuery = `filter=${encodeURIComponent(JSON.stringify(filterIds))}&`;
    }
    return this.constructedFetch(`wp/v2/rezept?per_page=${perPage}&offset=${offset}&${_filterQuery}&search=${encodeURIComponent(searchTerm)}&order=desc&enable_search=1`).then((_response) => _response);
  },

  searchPosts(searchTerm, perPage = 30, offset = 0) {
    return this.constructedFetch(`kh/v1/searchArticles?search=${encodeURIComponent(searchTerm)}`).then((_response) => {
      let include = '';
      const ids = _response.result.map(v => v.post_id).join(',');
      if (ids) {
        include = `&include=${ids}`;
        return this.constructedFetch(`wp/v2/posts?per_page=${perPage}&offset=${offset}${include}&_embed`).then((_response) => _response);
      } else {
        return Promise.resolve([]);
      }
    });
  },

  searchUsers(searchTerm, perPage = 30, offset = 0) {
    return this.constructedFetch(`wp/v2/users?per_page=${perPage}&offset=${offset}&search=${encodeURIComponent(searchTerm)}`).then((_response) => _response);
  },

  searchChannelPosts(searchTerm, perPage = 30, offset = 0) {
    return this.constructedFetch(`wp/v2/channel_post?per_page=${perPage}&offset=${offset}&filter[s]=${encodeURIComponent(searchTerm)}&order=desc&enable_search=1`).then((_response) => _response);
  },

  getYoastData(_slug) {
    return this.constructedFetch(`kh/v1/getYoastData?slug=${encodeURIComponent(_slug)}`).then((_response) => _response);
  },

  fetchArticleBySlug(_slug) {
    return this.constructedFetch(`wp/v2/posts?slug=${encodeURIComponent(_slug)}`).then((_response) => _response);
  },

  getArticlesByCategory({ category, perPage = 10, offset = 0 }) {
    if (category) {
      return this.constructedFetch(`kh/v1/getArticlesByCategory?category=${category}&per_page=${perPage}&offset=${offset}`).then((_response) => _response);
    }
    return this.constructedFetch(`kh/v1/getArticlesByCategory?per_page=${perPage}&offset=${offset}`).then((_response) => _response);
  },

  fetchCategoryById(_id) {
    return this.constructedFetch(`wp/v2/categories?include=${encodeURIComponent(_id)}`).then((_response) => _response);
  },

  fetchMediaById(_id) {
    return this.constructedFetch(`wp/v2/media?include=${encodeURIComponent(_id)}`).then((_response) => _response);
  },

  fetchUserById(_id) {
    return this.constructedFetch(`wp/v2/users/${_id}`).then((_response) => _response);
  },

  fetchUserBySlug(_slug) {
    return this.constructedFetch(`wp/v2/users?slug=${encodeURIComponent(_slug)}`).then((_response) => _response);
  },

  fetchPageBySlug(_slug) {
    return this.constructedFetch(`wp/v2/pages?slug=${encodeURIComponent(_slug)}`).then((_response) => _response);
  },

  fetchCommentsByPostId(_id, perPage = 100) {
    return this.constructedFetch(`wp/v2/comments?post=${encodeURIComponent(_id)}&per_page=${perPage}`, false).then((_response) => _response);
  },

  fetchCommentsById(_id) {
    return this.constructedFetch(`wp/v2/comments/${encodeURIComponent(_id)}`, false).then((_response) => _response);
  },

  getRatingsForRecipe(_id) {
    return this.constructedFetch(`kh/v1/getRatingsForRecipe?id=${encodeURIComponent(_id)}`, false).then((_response) => _response);
  },

  getFavoritesForRecipe(_id) {
    return this.constructedFetch(`kh/v1/getFavoritesForRecipe?id=${encodeURIComponent(_id)}`, false).then((_response) => _response);
  },
  getFavoritesForRecipeBySlug(_slug) {
    return this.constructedFetch(`kh/v1/getFavoritesForRecipe?slug=${encodeURIComponent(_slug)}`, false).then((_response) => _response);
  },
  getHomepage() {
    return this.constructedFetch('kh/v1/homepage').then((_response) => {
      store.dispatch(setHomepageData(_response));
      return _response;
    });
  },

  getInitData() {
    return this.constructedFetch('kh/v1/init').then((_response) => {
      store.dispatch(setInitData(_response));
      return _response;
    });
  },

  getFilters() {
    return this.constructedFetch('kh/v1/getFilters').then((_response) => {
      store.dispatch(setFilters(_response));
      return _response;
    });
  },

  auth(username, password, actionIntents = []) {
    store.dispatch(authInProgress());
    let actionIntentParams = '';
    if (actionIntents.length) {
      actionIntents.forEach((intent, i) => {
        actionIntentParams += `${i > 0 ? '&' : ''}actionIntent=${encodeURIComponent(intent)}`;
      });
    }
    return this.constructedFetch(`kh/v1/auth?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}${actionIntentParams ? `&${actionIntentParams}` : ''}`, false).then((_response) => {
      if (_response.success) {
        store.dispatch(authUser(_response.userdata));
        Bootstrap.setAuthToken(_response.userdata.authToken);
        Bootstrap.setHasLoggedIn();
      }
      store.dispatch(authFinished());
      return _response;
    });
  },

  authByToken(token) {
    store.dispatch(authInProgress());
    return this.constructedFetch(`kh/v1/auth/byToken?token=${encodeURIComponent(token)}&`, false).then((_response) => {
      if (_response.success) {
        store.dispatch(authUser(_response.userdata));
        Bootstrap.setAuthToken(_response.userdata.authToken);
      }
      store.dispatch(authFinished());
      return _response;
    });
  },

  register(_formData, actionIntents = []) {
    const queryParams = new URLSearchParams();

    if (actionIntents.length) {
      actionIntents.forEach((intent, i) => {
        queryParams.append('actionIntent', encodeURIComponent(intent));
      });
    }

    const queryParamsFragment = queryParams.toString();

    return this.constructedPost(`kh/v1/register${queryParamsFragment ? `?${queryParamsFragment}` : ''}`, _formData).then((_response) => _response);
  },

  sendComment(_recipe, _comment, _rating, _image, _parentId) {
    return this.constructedPost('kh/v1/sendComment', {
      recipe: _recipe,
      image: _image,
      comment: _comment,
      rating: _rating,
      parent_id: _parentId,
      auth: store.getState().content.userData.authToken,
    }).then((_response) => _response);
  },

  getUserStats(id) {
    const content = store.getState().content;
    return this.constructedPost('kh/v1/getUserStats', {
      auth: content.userData && content.userData.authToken,
      id,
    }).then((_response) => _response);
  },

  getUserData() {
    return this.constructedPost('kh/v1/getUserData', {
      auth: store.getState().content.userData.authToken,
    }).then((_response) => _response);
  },

  fetchTaxonomy(_name) {
    return this.constructedFetch(`wp/v2/${_name}?per_page=100`).then((_response) => _response);
  },

  sendRecipe(_recipeData) {
    _recipeData.auth = store.getState().content.userData.authToken;
    return this.constructedPost('kh/v1/sendRecipe', _recipeData).then((_response) => _response);
  },

  changeProfilePicture(_pictureData) {
    _pictureData.auth = store.getState().content.userData.authToken;
    return this.constructedPost('kh/v1/changeProfilePicture', _pictureData).then((_response) => _response);
  },

  changePassword(oldPassword, newPassword) {
    return this.constructedPost('kh/v1/changePassword', {
      auth: store.getState().content.userData.authToken,
      old: oldPassword,
      new: newPassword,
    }).then((_response) => _response);
  },

  setFavorite(recipeId, isFavorite) {
    return this.constructedPost('kh/v1/setFavorite', {
      auth: store.getState().content.userData.authToken,
      rezept_id: recipeId,
      is_favorite: isFavorite ? 1 : 0,
    }).then((_response) => _response);
  },

  setRating(recipeId, rating) {
    return this.constructedPost('kh/v1/setRating', {
      auth: store.getState().content.userData.authToken,
      rezept_id: recipeId,
      rating,
    }).then((_response) => _response);
  },

  changeEmail(email) {
    return this.constructedPost('kh/v1/changeEmail', {
      auth: store.getState().content.userData.authToken,
      email,
    }).then((_response) => _response);
  },

  changeReceiveEmailNotifications(enable) {
    return this.constructedPost('kh/v1/changeReceiveEmailNotifications', {
      auth: store.getState().content.userData.authToken,
      receive_notifications: enable ? 1 : 0,
    }).then((_response) => _response);
  },

  resetPassword(email) {
    return this.constructedPost('kh/v1/resetPassword', {
      email,
    }).then((_response) => _response);
  },

  verifyAccount(id, token) {
    console.log('verifying');
    return this.constructedPost('kh/v1/verifyAccount', {
      id,
      token,
    }).then((_response) => _response);
  },

  getNotifications({ unreadOnly = false, perPage = 10, offset = 0 }) {
    return this.constructedPost(`kh/v1/getNotifications?per_page=${perPage}&offset=${offset}`, {
      auth: store.getState().content.userData.authToken,
      unread_only: unreadOnly ? 1 : 0,
    }).then((_response) => _response);
  },

  getUnreadNotificationCount() {
    const userData = store.getState().content.userData;
    if (!userData) {
      return Promise.resolve({ success: false });
    }
    return this.constructedPost('kh/v1/getUnreadNotificationCount', {
      auth: userData.authToken,
    }).then((_response) => _response);
  },

  markNotificationsRead(notificationIds) {
    return this.constructedPost('kh/v1/markNotificationsRead', {
      auth: store.getState().content.userData.authToken,
      notification_ids: JSON.stringify(notificationIds),
    }).then((_response) => _response);
  },

  cmPublishRecipe(recipeId) {
    return this.constructedPost('kh/v1/cmPublishRecipe', {
      auth: store.getState().content.userData.authToken,
      recipe_id: recipeId,
    }).then((_response) => _response);
  },

  cmRejectRecipe(recipeId) {
    return this.constructedPost('kh/v1/cmRejectRecipe', {
      auth: store.getState().content.userData.authToken,
      recipe_id: recipeId,
    }).then((_response) => _response);
  },

  cmDeleteComment(commentId) {
    return this.constructedPost('kh/v1/cmDeleteComment', {
      auth: store.getState().content.userData.authToken,
      comment_id: commentId,
    }).then((_response) => _response);
  },

  cmGetNotifications(unreadOnly = false, notificationType) {
    return this.constructedPost('kh/v1/cmGetNotifications', {
      auth: store.getState().content.userData.authToken,
      notification_type: notificationType,
      unread_only: unreadOnly ? 1 : 0,
    }).then((_response) => _response);
  },

  cmGetRecipe(recipeId) {
    return this.constructedPost('kh/v1/cmGetRecipe', {
      auth: store.getState().content.userData.authToken,
      recipe_id: recipeId,
    }).then((_response) => _response);
  },

  cmGetUnreadNotificationCount(notificationType) {
    return this.constructedPost('kh/v1/cmGetUnreadNotificationCount', {
      auth: store.getState().content.userData.authToken,
      notification_type: notificationType,
    }).then((_response) => _response);
  },

  cmSetNotificationRead(notificationId, userId) {
    return this.constructedPost('kh/v1/cmSetNotificationRead', {
      auth: store.getState().content.userData.authToken,
      notification_id: notificationId,
      user_id: userId,
    }).then((_response) => _response);
  },

  // supported (optional) fields are: {
  //  vorname,
  //  nachname,
  //  strasse,
  //  hausnummer,
  //  postleitzahl,
  //  stadt,
  //  land
  // }
  changeUserPersonalData(_userData) {
    _userData.auth = store.getState().content.userData.authToken;
    return this.constructedPost('kh/v1/changeUserPersonalData', _userData).then((_response) => _response);
  },

  // Channel API
  //
  //
  getChannels() {
    return this.constructedFetch('kh/v1/getChannels').then((_response) => {
      if (_response.channels) {
        store.dispatch(setChannels(_response.channels));
      }
      return _response;
    });
  },

  /**
   * Expected params:
   *
   * auth,
   * title,
   * post_content,
   * with_image // only when an image is sent
   * image post data,
   * channel (taxonomy id),
   * opt mention_user_ids: JSON.stringify([ 1, 2, 3, 4 ])
   */
  sendChannelPost(channelPostData) {
    channelPostData.auth = store.getState().content.userData.authToken;
    return this.constructedPost('kh/v1/sendChannelPost', channelPostData).then((_response) => _response);
  },

  // order: desc
  // orderby: comment_count, favorites, date
  // filter: channel
  fetchChannelPosts({ noCache, filterIds, orderBy, authorIn, authorNotIn, postIn, perPage, offset }) {
    const cache = !noCache;
    let _filterQuery = `filter=${encodeURIComponent(JSON.stringify({}))}&`;
    if (filterIds) {
      _filterQuery = `filter=${encodeURIComponent(JSON.stringify(filterIds))}&`;
    }
    let _orderByQuery = '';
    if (orderBy) {
      _orderByQuery = `orderby=${encodeURIComponent(orderBy)}&`;
    }
    let _authorInQuery = '';
    if (authorIn) {
      _authorInQuery = `author_in=${encodeURIComponent(JSON.stringify(authorIn))}&`;
    }
    let _authorNotInQuery = '';
    if (authorNotIn) {
      _authorNotInQuery = `author_not_in=${encodeURIComponent(JSON.stringify(authorNotIn))}&`;
    }
    let _postInQuery = '';
    if (postIn) {
      _postInQuery = `post_in=${encodeURIComponent(JSON.stringify(postIn))}&`;
    }
    let _perPageQuery = '';
    if (perPage) {
      _perPageQuery = `per_page=${encodeURIComponent(perPage)}&`;
    }
    let _offsetQuery = '';
    if (offset) {
      _offsetQuery = `offset=${encodeURIComponent(offset)}&`;
    }
    return this.constructedFetch(`kh/v1/getChannelPosts?${_perPageQuery}${_offsetQuery}${_orderByQuery}${_filterQuery}${_authorInQuery}${_authorNotInQuery}${_postInQuery}`, cache).then((_response) => _response);
  },

  fetchChannelPostBySlug(_slug, useCache = false) {
    return this.constructedFetch(`wp/v2/channel_post?slug=${encodeURIComponent(_slug)}`, useCache).then((_response) => _response);
  },

  setChannelPostFavorite(channelPostId, isFavorite) {
    return this.constructedPost('kh/v1/setChannelPostFavorite', {
      auth: store.getState().content.userData.authToken,
      channel_post_id: channelPostId,
      is_favorite: isFavorite ? 1 : 0,
    }).then((_response) => _response);
  },

  setChannelPostCommentLike(commentId, isLiked) {
    return this.constructedPost('kh/v1/setChannelPostCommentLike', {
      auth: store.getState().content.userData.authToken,
      channel_post_comment_id: commentId,
      is_like: isLiked ? 1 : 0,
    }).then((_response) => _response);
  },

  sendChannelPostComment(channelPost, comment, image, parentId, mentionUserIds) {
    return this.constructedPost('kh/v1/sendChannelPostComment', {
      channel_post: channelPost,
      image,
      comment,
      parent_id: parentId,
      mention_user_ids: JSON.stringify(mentionUserIds),
      auth: store.getState().content.userData.authToken,
    }).then((_response) => _response);
  },

  deleteChannelPostComment(commentId) {
    return this.constructedPost('kh/v1/deleteChannelPostComment', {
      auth: store.getState().content.userData.authToken,
      comment_id: commentId,
    }).then((_response) => _response);
  },

  editChannelPostComment(commentId, newComment, shouldDeleteImage, newImage, mentionUserIds = []) {
    const payload = {
      auth: store.getState().content.userData.authToken,
      comment_id: commentId,
      comment: newComment,
      mention_user_ids: JSON.stringify(mentionUserIds),
      image: newImage,
      with_image: newImage ? 1 : 0,
      delete_image: shouldDeleteImage ? 1 : 0,
    };
    return this.constructedPost('kh/v1/modifyChannelPostComment', payload).then((_response) => _response);
  },

  deleteChannelPost(postId) {
    return this.constructedPost('kh/v1/deleteChannelPost', {
      auth: store.getState().content.userData.authToken,
      channel_post_id: postId,
    }).then((_response) => _response);
  },

  modifyChannelPost(postId, newTitle, newDescription, shouldDeleteImage, newImage) {
    const payload = {
      auth: store.getState().content.userData.authToken,
      channel_post_id: postId,
      title: newTitle,
      description: newDescription,
      image: newImage,
      with_image: newImage ? 1 : 0,
      delete_image: shouldDeleteImage ? 1 : 0,
    };
    return this.constructedPost('kh/v1/modifyChannelPost', payload).then((_response) => _response);
  },
};

export default API;
