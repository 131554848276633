import { connect } from 'react-redux';

import { togglePasswordModal } from '../../redux/modules/ui';
import RequestNewPasswordOverlay from './presentation';

export default connect(({ ui: { resetPasswordModalOpen } }) => ({
  resetPasswordModalOpen,
}), {
  togglePasswordModal,
})(RequestNewPasswordOverlay);
