import { produce } from 'immer';
import { DATA_LAYER_PUSH_ACTION } from './actions';

const initialState = {
  facebookConsent: false,
  adformConsent: false,
  matomoConsent: false,
  youtubeVideoConsent: false,
  ga4Consent: false,
  pinterestConsent: false,
};

export default function gtmConsentStateReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case DATA_LAYER_PUSH_ACTION:
        if (!action.payload || !action.payload[0] || !action.payload[0].action) return state;

        draft.facebookConsent = action.payload[0]['Facebook Pixel'];
        draft.adformConsent = action.payload[0].Adform;
        draft.matomoConsent = action.payload[0].Matomo;
        draft.youtubeVideoConsent = action.payload[0]['YouTube Video'];
        draft.ga4Consent = action.payload[0]['Google Analytics 4'];
        draft.pinterestConsent = action.payload[0].Pinterest;
        break;

      default:
        return state;
    }
  });
}
