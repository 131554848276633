import React from 'react';
import cx from 'classnames';

import './Styles.css';

const HeartLike = ({
  isSyncingFavCount,
  favCount,
  isFavoriteClicked,
  onClick,
  isInteractable = false,
  activeAnimatable = false,
  grayBackground = false,
  whiteBackground = false,
}) => (
  <button
    style={{ pointerEvents: isInteractable ? 'auto' : 'none' }}
    className={cx('HeartLike--likes',
      {
        'HeartLike--likes__active': isFavoriteClicked,
        'HeartLike--likes__animated': activeAnimatable,
        'HeartLike--currently-syncing': isSyncingFavCount,
        'HeartLike--grayBackground': grayBackground,
        'HeartLike--whiteBackground': whiteBackground,
      },
    )}
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      if (!isSyncingFavCount) {
        onClick && onClick(e);
      }
    }}
  >
    {(() => {
      // console.log(isSyncingFavCount)
    })()}
    {favCount}
  </button>
);

export default HeartLike;
