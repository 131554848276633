import React, { Fragment, memo } from 'react';
import { useDataLayer } from '../hooks/useDataLayer';
import { useInitTracker } from '../hooks/useInitTracker';
import { useScrollAnalytics } from '../hooks/useScrollAnalytics';

function AppGlobalHookLoader() {
  useDataLayer();
  useScrollAnalytics();
  useInitTracker();

  return <Fragment></Fragment>;
}

export default memo(AppGlobalHookLoader);
