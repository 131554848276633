import { connect } from 'react-redux';

import {
  toggleSignInUpModal,
  setSigninupModalAllFieldsRequired,
} from '../../redux/modules/ui';

import Presentation from './index.jsx';

export default connect(({
  content: {
    userData,
  },
}) => ({
  userData,
}), {
  toggleSignInUpModal,
  setSigninupModalAllFieldsRequired,
})(Presentation);
