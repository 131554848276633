import React, { Component } from 'react';
import cx from 'classnames';
import uniqWith from 'lodash.uniqwith';
import Measure from 'react-measure';

import './Styles.css';

class Image extends Component {
  state = {
    loaded: false,
    hidePlaceholder: false,
    layoutWidth: 0,
  }

  render() {
    const {
      title,
      alt,
      linkUrl,
      sizes,
      fallbackSrc,
      // noWebp,
      wrapperClassName,
      aspect,
      width,
      height,
      onLoad = () => {},
      onError = () => {},
    } = this.props;
    const {
      loaded,
      hidePlaceholder,
      layoutWidth,
    } = this.state;

    const lw = Math.max(width || 0, layoutWidth);

    const isEmptyImage = sizes && Object.keys(sizes).find(key => !sizes[key]);
    const sizeValues = Object.values(sizes || []);
    let sources = sizes
      ? Object.keys(sizes).filter((v, i) => i % 3 === 0).map((v, i) => ({
        name: sizeValues[i * 3],
        width: sizeValues[i * 3 + 1],
        height: sizeValues[i * 3 + 2],
      }))
      : [];
    sources = uniqWith(sources, (a, b) => a.width === b.width).sort((a, b) => a.width - b.width);

    const desiredPixelRatio = Math.min(window.devicePixelRatio, 1.75);
    let fittingImage = sources.reduce((a, c) => {
      const dist = lw * desiredPixelRatio - c.width;
      const distSq = dist * dist;
      if (a.distSq === undefined) {
        return { distSq, ...c };
      }
      if (distSq < a.distSq) {
        return { distSq, ...c };
      }
      return a;
    }, {});

    if (!fittingImage.name) {
      fittingImage = { name: fallbackSrc };
    }

    /* const srcSetString = sources.length > 0
      ? sources.reduce((acc, cur, i, self) => {
        const name = cur.name
        const width = cur.width
        acc += `${name} ${width}w${i === self.length - 1 ? '' : ','}`
        return acc
      }, '')
      : undefined */

    // const fallbackSrcWebp = `${fallbackSrc}.webp`
    /* const srcSetStringWebp = sizes
      ? sources.reduce((acc, cur, i, self) => {
        const name = cur.name
        const width = cur.width
        acc += `${name}.webp ${width}w${i === self.length - 1 ? '' : ','}`
        return acc
      }, '')
      : undefined */

    let pictureEl;
    if (aspect && width && height) {
      pictureEl = (
        <picture
          // data-sizes="auto"
          // data-aspectratio={aspect}
          className={cx('ContentBlockImage--img', {
            [wrapperClassName]: !!wrapperClassName,
          })}
          style={{ opacity: loaded ? 1 : 0, width: `${width}px`, height: `${height}px` }}
          onLoad={() => {
            this.setState({ loaded: true });
            onLoad();
          }}
          onError={onError}
        >
          {
          //! noWebp && <source
            // type="image/webp"
            // data-srcset={`${fittingImage.name}.webp`}
            // data-srcset={srcSetStringWebp}
          /// >
          }
          <img
            title={title}
            alt={alt}
            data-src={fittingImage.name}
            // data-srcset={srcSetString}
            // data-aspectratio={aspect}
            className="lazyload"
            // data-sizes="auto"
            style={{ width: `${width}px`, height: `${height}px` }}
          />
        </picture>
      );
    } else {
      pictureEl = (
        <picture
          // data-sizes="auto"
          className={cx('ContentBlockImage--img', {
            [wrapperClassName]: !!wrapperClassName,
          })}
          style={{ opacity: loaded ? 1 : 0, width: '100%' }}
          onLoad={() => {
            this.setState({ loaded: true });
            onLoad();
          }}
          onError={onError}
        >

          <img
            title={title}
            alt={alt}
            data-src={fittingImage.name}
            // data-srcset={srcSetString}
            className="lazyload"
            style={{ width: '100%' }}
            // data-sizes="auto"
          />
        </picture>
      );
    }

    const contentBlock = (
      <figure className="ContentBlockImage--figure">
        {!isEmptyImage && !hidePlaceholder && (
          <div
            style={{ opacity: loaded ? 0 : 1 }}
            onTransitionEnd={() => {
              this.setState({ hidePlaceholder: true });
            }}
            className="ContentBlockImage--placeholder"/>
        )}
        {!isEmptyImage && pictureEl}
      </figure>
    );

    const linkContentBlock = (
      <a href={linkUrl} target="_blank" rel="noopener noreferrer">
        {contentBlock}
      </a>
    );

    return (
      <Measure
        bounds
        onResize={({ bounds: { width } }) => {
          this.setState({ layoutWidth: width });
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className="ContentBlockImage">
            {linkUrl ? linkContentBlock : contentBlock}
          </div>
        )}
      </Measure>
    );
  }
}

export default Image;
