export const SET_GEWINNSPIEL_ANSWER = 'ui/SET_GEWINNSPIEL_ANSWER';
export const SET_SIGN_IN_UP_MODAL_ALL_FIELDS_REQUIRED = 'ui/SET_SIGN_IN_UP_MODAL_ALL_FIELDS_REQUIRED';
export const MENU_TOGGLE = 'ui/menu-toggle';
export const SUBMENU_TOGGLE = 'ui/submenu-toggle';
export const TOGGLE_SIGNINSIGNUPMODAL = 'ui/toggle-signinsignupmodal';
export const TOGGLE_NEWFORUMPOST = 'ui/toggle-newforumpostmodal';
export const RESET_PASSWORD_MODAL_OPEN = 'ui/reset-password-modal-open';
export const SET_ACTIVE_RECIPE_ID = 'ui/set-active-recipe-name';
export const SET_SIGN_UP_OVERLAY_ACTIVE_SECTION = 'ui/set-sign-up-overlay-active-section';
export const SET_VIEWPORT_WIDTH = 'ui/set-viewport-width';

const initialState = {
  gewinnSpielAnswer: null,
  cookieConsentIsInline: false,
  menuOpen: false,
  submenuOpen: [],
  signinupModalOpen: false,
  signinupModalAllowClose: true,
  signinupModalAllFieldsRequired: false,
  newForumPostModalOpen: false,
  resetPasswordModalOpen: false,
  activeRecipeID: null,
  signUpOverlayActiveSection: null,
  viewportWidth: window.innerWidth,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GEWINNSPIEL_ANSWER: {
      return Object.assign({}, state, {
        gewinnSpielAnswer: action.gewinnSpielAnswer,
      });
    }
    case MENU_TOGGLE: {
      return Object.assign({}, state, {
        menuOpen: !state.menuOpen,
      });
    }
    case SUBMENU_TOGGLE: {
      const _submenuOpen = [];
      let _found = false;
      for (let i = 0; i < state.submenuOpen.length; i++) {
        if (state.submenuOpen[i] === action.menuIndex) {
          _found = true;
        } else {
          _submenuOpen.push(state.submenuOpen[i]);
        }
      }

      if (!_found) _submenuOpen.push(action.menuIndex);

      return Object.assign({}, state, {
        submenuOpen: _submenuOpen,
      });
    }
    case TOGGLE_SIGNINSIGNUPMODAL: {
      return Object.assign({}, state, {
        signinupModalOpen: !state.signinupModalOpen,
        signinupModalAllowClose: action.allowClose,
      });
    }
    case SET_SIGN_IN_UP_MODAL_ALL_FIELDS_REQUIRED: {
      return Object.assign({}, state, {
        signinupModalAllFieldsRequired: action.signinupModalAllFieldsRequired,
      });
    }
    case TOGGLE_NEWFORUMPOST: {
      return Object.assign({}, state, {
        newForumPostModalOpen: !state.newForumPostModalOpen,
      });
    }
    case RESET_PASSWORD_MODAL_OPEN: {
      return Object.assign({}, state, {
        resetPasswordModalOpen: !state.resetPasswordModalOpen,
      });
    }
    case SET_ACTIVE_RECIPE_ID: {
      return Object.assign({}, state, {
        activeRecipeID: action.activeRecipeID,
      });
    }
    case SET_SIGN_UP_OVERLAY_ACTIVE_SECTION: {
      return Object.assign({}, state, {
        signUpOverlayActiveSection: action.signUpOverlayActiveSection,
      });
    }
    case SET_VIEWPORT_WIDTH: {
      return Object.assign({}, state, {
        viewportWidth: action.viewportWidth,
      });
    }

    default:
      return state;
  }
};

export function toggleMenu() {
  return {
    type: MENU_TOGGLE,
  };
}

export function toggleSubmenu(menuIndex) {
  return {
    type: SUBMENU_TOGGLE,
    menuIndex,
  };
}

export function toggleSignInUpModal({ allowClose = true } = {}) {
  return {
    type: TOGGLE_SIGNINSIGNUPMODAL,
    allowClose,
  };
}

export function setSigninupModalAllFieldsRequired(signinupModalAllFieldsRequired) {
  return {
    type: SET_SIGN_IN_UP_MODAL_ALL_FIELDS_REQUIRED,
    signinupModalAllFieldsRequired,
  };
}

export function toggleNewForumPostModal() {
  return {
    type: TOGGLE_NEWFORUMPOST,
  };
}

export function togglePasswordModal() {
  return {
    type: RESET_PASSWORD_MODAL_OPEN,
  };
}

export function setActiveRecipeID(id) {
  return {
    type: SET_ACTIVE_RECIPE_ID,
    activeRecipeID: id,
  };
}

export function setSignUpOverlayActiveSection(signUpOverlayActiveSection) {
  return {
    type: SET_SIGN_UP_OVERLAY_ACTIVE_SECTION,
    signUpOverlayActiveSection,
  };
}

export function setViewportWidth(viewportWidth) {
  return {
    type: SET_VIEWPORT_WIDTH,
    viewportWidth,
  };
}

export function setGewinnSpielAnswer(gewinnSpielAnswer) {
  return {
    type: SET_GEWINNSPIEL_ANSWER,
    gewinnSpielAnswer,
  };
}
