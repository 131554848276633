import { useEffect, useState } from 'react';
import { history } from '../redux/store';

export function useLocation() {
  const [loc, setLoc] = useState(history.location);

  useEffect(() => {
    history.listen((location, ...params) => {
      setLoc(location);
    });
  }, []);

  return loc;
}
