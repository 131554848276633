import React from 'react';
import './Styles.css';

function FormLabel(props) {
  return (
    <label className="FormLabel" {...props}>
      {props.children}
    </label>
  );
}

export default FormLabel;
