import React, { createContext, useEffect, useState } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';

export const ReduxStoreContext = ReactReduxContext;

function createStoreContext(current, store) {
  if (!store) return current;
  const state = store.getState();
  const dispatch = store.dispatch;
  if (current && current.state === state && current.store === store && current.dispatch === dispatch) return current;
  return { store, state, dispatch };
}

export default function ReduxProvider({ children, store }) {
  const [storeContext, setStoreContext] = useState(createStoreContext(null, store));

  useEffect(() => {
    if (!store) return;
    setStoreContext(current => createStoreContext(current, store));
  }, [store]);

  return (
    <Provider store={store}>
      <ReduxStoreContext.Provider value={storeContext}>
        {children}
      </ReduxStoreContext.Provider>
    </Provider>
  );
}
