import React from 'react';
import cx from 'classnames';

import './Styles.css';

function FormButton({ light, isBrown, lowercase, ...rest }) {
  return (
    <button
      className={cx('FormButton', {
        isBrown,
      })}
      data-light={light ? 'true' : 'false'}
      data-lowercase={lowercase ? 'true' : 'false'}
      {...rest}
    />
  );
}

export default FormButton;
