import format from 'format-number';

export const numberFormat = (round = 0) => format({
  integerSeparator: '.',
  decimal: ',',
  round,
});

export function getOverallRecipeTime({ gesamtzeit, zubereitungszeit, backzeit }) {
  if (!zubereitungszeit && !backzeit) {
    return gesamtzeit;
  }
  return (parseFloat(zubereitungszeit) || 0) + (parseFloat(backzeit) || 0);
}

const _ZERO_PADDING = '00000000';
const _GROUPS = [0, 0, 0, 0, 0, 0];

export function getGUID() {
  _GROUPS[0] = Math.floor(Math.random() * 0x10000000).toString(16);
  _GROUPS[1] = Math.floor(Math.random() * 0x10000).toString(16);
  _GROUPS[2] = (Math.floor(Math.random() * 0x1000) + 0x4000).toString(16);
  _GROUPS[3] = (Math.floor(Math.random() * 0x4000) + 0x8000).toString(16);
  _GROUPS[4] = Math.floor(Math.random() * 0x1000000).toString(16);
  _GROUPS[5] = Math.floor(Math.random() * 0x1000000).toString(16);
  return `${_GROUPS[0] + _ZERO_PADDING.slice(0, 8 - _GROUPS[0].length)}-${_GROUPS[1] + _ZERO_PADDING.slice(0, 4 - _GROUPS[1].length)}-${_GROUPS[2] + _ZERO_PADDING.slice(0, 4 - _GROUPS[2].length)}-${_GROUPS[3] + _ZERO_PADDING.slice(0, 4 - _GROUPS[3].length)}-${_GROUPS[4] + _ZERO_PADDING.slice(0, 6 - _GROUPS[4].length) + _GROUPS[5] + _ZERO_PADDING.slice(0, 6 - _GROUPS[5].length)}`;
}

export const getScrollY = (() => {
  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
  return () => supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
})();

export function getLocalStorageItemSafe(item) {
  try {
    return localStorage.getItem(item);
  } catch (ex) {
    // ignore security exception in case local storage is disabled.
  }
  return null;
}

export function setLocalStorageItemSafe(item, value) {
  try {
    return localStorage.setItem(item, value);
  } catch (ex) {
    // ignore security exception in case local storage is disabled.
  }
  return null;
}

export const encrypt = (value) => btoa((value + 23) * 7);

export const decrypt = (value) => atob(value) / 7 - 23;
