import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';

import useWindowSize from '../../hooks/useWindowSize';
import RecipeTeaser from '../../components/RecipeTeaser';
import Typography from '../../elements/Typography';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { trackEvent } from '../../tools/Tracker';
import API from '../../tools/API';
import HighlightedButton from '../../elements/HighlightedButton';

export default function RecipeSlider({ description, title, searchHint, moreButtonText, moreButtonLink }) {
  const size = useWindowSize(100);
  const [swiper, setSwiper] = useState();
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    // Fetch recipes when the component mounts
    API.fetchRecipes({ perPage: 10, offset: 0 })
      .then(result => {
        if (result && result.posts.length > 0) {
          setRecipes(result.posts); // Update the state with fetched recipes
        }
      })
      .catch(error => {
        console.error('Failed to fetch recipes:', error);
      });
  }, []);

  const moreButtonClick = (event, href) => {
    trackEvent('Interaktion', 'Click_Rezept-Suche');
    window.open(href, '_self');
    event.preventDefault();
  };

  return (
    <aside className="variations">
      <Typography tag="h2" dangerousInnerHTML>{ title }</Typography>
      <Typography>{ description }</Typography>
      <div className="variations-swiper-container">
        <Swiper
          modules={[EffectCoverflow]}
          key={ size.width /* rerender swiper on window size change */ }
          onSwiper={ setSwiper }
          slidesPerView={ 3 }
          spaceBetween={ 70 }
          loop

          effect={'coverflow'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 300,
            modifier: 1,
            slideShadows: false,
          }}

          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 30,
              effect: false,
            },
            550: {
              slidesPerView: 2,
              spaceBetween: 30,
              effect: false,
            },
            720: {
              slidesPerView: 3,
              spaceBetween: 30,
              effect: false,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 50,
              effect: false,
            },
          }}
        >
          { recipes.map((recipe, i) => (
            <SwiperSlide key={ i } onClick={ () => trackEvent('Interaktion', 'Click_Rezept', (recipe.title && recipe.title.rendered) || recipe.post_title) }>
              <RecipeTeaser
                recipe={ JSON.parse(JSON.stringify(recipe)) }
              />
            </SwiperSlide>
          )) }
        </Swiper>
        <button className="swiper-button-next" onClick={ () => swiper.slideNext() }></button>
        <button className="swiper-button-prev" onClick={ () => swiper.slidePrev() }></button>
      </div>
      <Typography tag="h2" size="middle" level={ 3 } className="mobile-notop">{ searchHint }</Typography>
      <a className="link-unstyled" href={ moreButtonLink } onClick={ (event) => moreButtonClick(event, moreButtonLink) }><HighlightedButton>{ moreButtonText }</HighlightedButton></a>
    </aside>
  );
}
