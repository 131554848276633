import moment from 'moment-timezone';

moment.locale('de', {
  relativeTime: {
    future: 'In %s',
    past: 'Vor %s',
    s: 'einigen Sekunden',
    ss: '%d Sekunden',
    m: 'eine Minute',
    mm: '%d Minuten',
    h: 'einer Stunde',
    hh: '%d Stunden',
    d: 'einem Tag',
    dd: '%d Tagen',
    M: 'einem Monat',
    MM: '%d Monaten',
    y: 'einem Jahr',
    yy: '%d Jahren',
  },
});

export const localizeDate = date => moment
  .tz(date, 'YYYY.MM.DD, HH:mm', 'GMT')
  .local();

export const getLocalizedDateString = date => localizeDate(date)
  .format('YYYY.MM.DD, HH:mm');

export const fromNow = date => {
  const normalizedDate = localizeDate(date);
  return normalizedDate.isValid() ? normalizedDate.fromNow() : date;
};

export const compareTwoDates = (d1, d2) => {
  const normd1 = localizeDate(d1);
  const normd2 = localizeDate(d2);
  return normd2.diff(normd1);
};

export const dataURLtoFile = (dataurl, filename) => new Promise(resolve => {
  fetch(dataurl)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], filename);
      file.preview = dataurl;
      file.originalFile = { preview: dataurl };
      file.previewFile = new File([blob], filename);
      resolve(file);
    });
});

export const getYoutubeIdFromURL = url => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
};

export const isValidImageURl = url => !!url.match(/\.(jpeg|jpg|gif|png|svg)$/);

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const stripHTMLTags = (() => {
  const div = document.createElement('div');
  return html => {
    div.innerHTML = html;
    return div.textContent || div.innerText;
  };
})();

export const mapResponsePostsToThreads = (posts) => posts.map(({
  ID: postID,
  comment_count: commentsCount,
  post_date_gmt: rawWPDate,
  post_title: title,
  post_name: slug,
  post_content: postContent,
  channelPostData: {
    channel: { slug: parentChannelSlug, term_id: parentChannelID },
    favorites: likes,
    authorName,
    author_id: authorID,
    mainImage,
  },
}, i, self) => ({
  postID,
  title,
  authorName,
  authorID,
  likes: likes || 0,
  commentsCount: commentsCount || 0,
  date: fromNow(rawWPDate),
  mainImage,
  rawWPDate,
  slug,
  postContent,
  parentChannelSlug,
  parentChannelID,
  uiRenderIndex: self.length - i,
}));
