import React from 'react';
import classNames from 'classnames';
import './Styles.css';

function RecipeRating({ rating, count, showCount }) {
  if (!rating) {
    rating = 0;
  }
  if (!count) {
    count = 0;
  }
  rating = Math.round(parseFloat(rating));
  const stars = [...Array(5).keys()].map(v => (
    <div
      key={v}
      className={classNames('RecipeRating--star', { 'RecipeRating--star__disabled': v >= rating })}
    />
  ));

  let countView;
  if (showCount) {
    countView = (
      <span className="RecipeRating--count">{`(${count})`}</span>
    );
  }

  return (
    <div className="RecipeRating">
      <div className="RecipeRating--wrapper">
        {stars}
        {countView}
      </div>
    </div>
  );
}

export default RecipeRating;
