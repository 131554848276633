import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import './index.css';
import App from './App';

// Import service worker unregister function
import { unregister } from './registerServiceWorker';

// Get the root container element
const rootElement = document.getElementById('root');

// Set the Modal app element
Modal.setAppElement(rootElement);

// Create a root
const root = createRoot(rootElement);

// Render the App component into the root
root.render(<App />);

// Unregister the service worker as per issue recommendations
unregister();
