import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { useParams, useLocation } from 'react-router-dom';

import API from '../../tools/API';
import STATIC_SEO from '../../static-seo.json';

import './Styles.css';

const Page = ({ className, children }) => {
  const [pageTitle, setPageTitle] = useState('');
  const { slug } = useParams(); // get 'slug' from the URL
  const location = useLocation(); // get the current location object

  const setPageTitleFromAPI = (pageSlug, url) => {
    if (pageSlug) {
      API.getYoastData(pageSlug).then((_response) => {
        if (!_response.success) {
          setPageTitle(STATIC_SEO['/']);
          return;
        }
        const { yoast_meta: { yoast_wpseo_title: newPageTitle } = {} } = _response;
        setPageTitle(newPageTitle);
      });
    } else {
      const newPageTitle = STATIC_SEO[url]
        ? STATIC_SEO[url].yoast_wpseo_title
        : STATIC_SEO['/'].yoast_wpseo_title;
      setPageTitle(newPageTitle);
    }
  };

  useEffect(() => {
    setPageTitleFromAPI(slug, location.pathname);
  }, [slug, location.pathname]);

  return (
    <div>
      <Helmet>
        <title>{typeof pageTitle !== 'object' ? pageTitle : ''}</title>
      </Helmet>
      <div className={classNames('Page', className)}>
        {children}
      </div>
    </div>
  );
};

export default Page;
