import { createSelector } from 'reselect';
import { GRUNDTEIGE_BASE_ROUTE } from '../../AppView/routeNames';

function selectContentSlice(state) {
  return state.content;
}

export const selectMenuData = createSelector(
  selectContentSlice,
  ({ menuData }) => {
    const menu = menuData.map(item => {
      if (item.url === GRUNDTEIGE_BASE_ROUTE) {
        const baseDoughPages = [
          { title: 'Pizza, Flammkuchen und Focaccia', slug: 'pizza-flammkuchen-und-focaccia' },
          { title: 'Pfannkuchenteig', slug: 'pfannkuchenteig' },
          { title: 'Brot- und Brötchenteig', slug: 'brot-und-brotchenteig' },
          { title: 'Waffelteig', slug: 'waffelteig' },
          { title: 'Biskuitteig', slug: 'biskuitteig' },
          { title: 'Mürbeteig', slug: 'muerbeteig' },
          { title: 'Hefeteig', slug: 'hefeteig' },
          { title: 'Rührteig', slug: 'ruehrteig' },
        ];
        item.menu = baseDoughPages.map(({ title, slug }) => ({
          title,
          url: `${item.url}/${slug}`,
        }));
      }
      return item;
    });
    return menu;
  },
);

/*
export const selectMenuData = createSelector(
  selectAllBaseDoughPages,
  selectContentSlice,
  (baseDoughPages, { menuData }) => {
    const menu = menuData.map(item => {
      if (item.url === GRUNDTEIGE_BASE_ROUTE) {

        item.menu = baseDoughPages.map(({ title, slug }) => ({
          title,
          url: `${item.url}/${slug}`,
        }));
      }
      return item;
    });
    return menu;
  },
);

*/
