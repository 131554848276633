import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import Measure from 'react-measure';
import { Link } from 'react-router-dom';

import { getOverallRecipeTime } from '../../tools/helper';

import Headline from '../../elements/Headline';
import RecipeRating from '../RecipeRating';
import RecipeDuration from '../RecipeDuration';
import RecipeDifficulty from '../RecipeDifficulty';
import RecipeAuthor from '../RecipeAuthor';
import Image from '../Image';
import HeartLike from '../HeartLike';

import API from '../../tools/API';
import analyticsTracker from '../../tools/Tracker';

import './Styles.css';

class RecipeTeaser extends Component {
  state = {
    componentWidth: 0,
    isSyncingFavCount: false,
    favCount: 0,
    isLiked: false,
    imageHeight: null,
  }

  onFavorited = () => {
    const { userData, authUser, toggleSignInUpModal, recipe: { ID: id } } = this.props;
    const { favCount } = this.state;
    if (!userData) {
      toggleSignInUpModal();
      return;
    }
    const isFavoriteClicked = userData && userData.favorites && userData.favorites.indexOf(id) !== -1;
    this.setState({
      isSyncingFavCount: true,
      isLiked: !isFavoriteClicked,
      favCount: favCount + (isFavoriteClicked ? -1 : 1),
    });
    API.setFavorite(id, !isFavoriteClicked).then(() => {
      API.getUserData().then((response) => {
        if (response.success) {
          API.getFavoritesForRecipe(id).then(v => {
            authUser(response.userdata);
            this.setState({
              favCount: parseInt(v.favorite_count, 10),
              isSyncingFavCount: false,
              isLiked: response.userdata && response.userdata.favorites && response.userdata.favorites.indexOf(id) !== -1,
            });
          });
        }
      });
    });

    if (!isFavoriteClicked) {
      analyticsTracker.init().trackEvent('favoriten', 'like');
    }
  }

  render() {
    const {
      viewportWidth,
      className,
      pictureHeightRatio,
      recipe,
      related,
      userData,
      isLiked,
      showPicture = true,
      showMeta = true,
      hoverable = true,
      linkable = true,
      queryParams = '',
      setCommentOverlayOpen,
      setCommentRecipe,
      onAuthorClick,
      onLinkClick = () => {},
      push,
    } = this.props;

    const {
      isSyncingFavCount,
      favCount,
      imageHeight,
      componentWidth,
    } = this.state;

    if (!recipe) {
      return (
        <div>loading...</div>
      );
    }

    const recipeData = recipe.recipeData;

    if (!recipeData) {
      return null;
    }

    const isMobileResolution = viewportWidth < 800;

    let { mainImageWidth, mainImageHeight } = recipeData;

    mainImageWidth = pictureHeightRatio ? componentWidth : mainImageWidth;
    mainImageHeight = pictureHeightRatio ? imageHeight : mainImageHeight;

    const aspect = mainImageWidth / mainImageHeight;
    const componentHeight = componentWidth / aspect;

    if (recipeData) {
      delete recipeData.sizes.thumbnail;
      delete recipeData.sizes['thumbnail-width'];
      delete recipeData.sizes['thumbnail-height'];
    }

    const isFavoriteClicked = isSyncingFavCount
      ? isLiked
      : userData && userData.favorites && userData.favorites.indexOf(recipe.id) !== -1;

    return (
      <Measure
        bounds
        onResize={({ bounds: { width: componentWidth, height: componentHeight } }) => {
          if (pictureHeightRatio) {
            const imageHeight = componentHeight * pictureHeightRatio;
            this.setState({ imageHeight });
          }
          if (componentWidth >= 130) {
            this.setState({ componentWidth });
          } else {
            // setTimeout(() => {
            //   this.setState({ componentWidth })
            // }, 1000)
          }
        }}
      >
        {({ measureRef }) => (
          <article
            ref={measureRef}
            className={cx(className, 'RecipeTeaser', {
              'RecipeTeaser--hoverable': hoverable,
            })}
          >
            <Link
              style={{ cursor: linkable ? 'pointer' : 'default' }}
              to={recipe.post_name ? `/rezept/${recipe.post_name}?${queryParams}` : `/rezept/${recipe.slug}?${queryParams}`}
              onClick={e => {
                if (!(recipe.post_name || recipe.slug)) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                if (!linkable) {
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  onLinkClick();
                }
              }}
            >
              {showPicture && (
                <header>
                  <figure
                    style={{ paddingTop: `${100 / aspect}%` }}
                    className="RecipeTeaser--figure">
                    <div className="RecipeTeaser--category" />
                    <RecipeDuration
                      isInTeaserCard={true}
                    >
                      {`${getOverallRecipeTime(recipeData)} Min.`}
                    </RecipeDuration>
                    <div className={cx('ResipeTeaser--heart-container')}>
                      <HeartLike
                        whiteBackground={true}
                        isSyncingFavCount={isSyncingFavCount}
                        activeAnimatable={true}
                        favCount={favCount}
                        isFavoriteClicked={isFavoriteClicked}
                        onClick={this.onFavorited}
                        isInteractable={true}
                      />
                    </div>
                    {
                      !!componentWidth && !!componentHeight && (
                        <Image
                          wrapperClassName="RecipeTeaser--figure--image"
                          fallbackSrc={recipeData.mainImage}
                          sizes={recipeData.sizes}
                          title={recipeData.imageTitle}
                          alt={recipeData.imageAlt}
                          aspect={aspect}
                          width={componentWidth}
                          height={imageHeight || componentHeight}
                        />
                      )
                    }
                  </figure>
                </header>
              )}
              {showMeta && (
                <main className="RecipeTeaser--main">
                  <Headline
                    shouldBreak={false}
                    className="RecipeTeaser--headline">
                    {!related && (recipe.title ? recipe.title.rendered : recipe.post_title)}
                    {related && recipe.post_title}
                  </Headline>
                </main>
              )}
            </Link>
            {showMeta && (
              <Fragment>
                <Link
                  className="RecipeTeaser--authorLink"
                  to={
                    isMobileResolution
                      ? (recipe.post_name ? `/rezept/${recipe.post_name}` : `/rezept/${recipe.slug}`)
                      : `/benutzer/${recipeData.authorName}`
                  }
                  onClick={e => {
                    if (!(recipe.post_name || recipe.slug)) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                    if (onAuthorClick) {
                      onAuthorClick();
                    }
                  }}
                >
                  <RecipeAuthor
                    author={recipeData.authorName}
                    crown={recipeData.user_level}
                    onAuthorClick={onAuthorClick}
                  />
                </Link>
                <div
                  className="RecipeTeaser--rating-wrapper"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isMobileResolution) {
                      push(recipe.post_name ? `/rezept/${recipe.post_name}` : `/rezept/${recipe.slug}`);
                    } else {
                      setCommentOverlayOpen(true);
                      setCommentRecipe(recipe);
                    }
                  }}
                >
                  <RecipeRating rating={recipeData.rating} count={recipeData.rating_count} showCount />
                </div>
                <Link
                  style={{ cursor: linkable ? 'pointer' : 'default' }}
                  to={recipe.post_name ? `/rezept/${recipe.post_name}` : `/rezept/${recipe.slug}`}
                  onClick={e => {
                    if (!(recipe.post_name || recipe.slug)) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                    if (!linkable) {
                      e.preventDefault();
                      e.stopPropagation();
                    } else {
                      onLinkClick();
                    }
                  }}
                >
                  <main className="RecipeTeaser--main">
                    <div className="RecipeTeaser--duration--difficulty">
                      <RecipeDifficulty difficulty={recipeData.difficulty} />
                    </div>
                  </main>
                </Link>
              </Fragment>
            )}
          </article>
        )}
      </Measure>
    );
  }
}

export default RecipeTeaser;
