import { useEffect } from 'react';
import { dataLayerPushAction } from '../redux/modules/gtm-data-layer/actions';
import { useDispatch } from './useDispatch';

export function useDataLayer() {
  const dispatch = useDispatch();

  useEffect(() => {
    function listener(event) {
      const sanitizedData = sanitizeData(event.detail);
      dispatch(dataLayerPushAction(sanitizedData));
    }

    window.addEventListener('datalayerpush', listener);

    return () => window.removeEventListener('datalayerpush', listener);
  }, [dispatch]); // Correctly capture dispatch
}

function sanitizeData(data) {
  if (data === null || data === undefined) return data;

  if (typeof data === 'object' && !Array.isArray(data)) {
    if (data instanceof Date) {
      return data.toISOString();
    } else if (data instanceof Element) {
      return {
        id: data.id,
        className: data.className,
        tagName: data.tagName,
      };
    }
    const newData = {};
    for (const key in data) {
      newData[key] = sanitizeData(data[key]);
    }
    return newData;
  }

  if (Array.isArray(data)) {
    return data.map(item => sanitizeData(item));
  }

  return data;
}
