export default function setTimeouts(callback, timeouts) {
  const timeoutIds = [];

  timeouts.forEach(ms => {
    timeoutIds.push(setTimeout(() => {
      callback(ms);
    }, ms));
  });

  return function clearTimeouts() {
    timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
  };
}
