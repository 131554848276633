import { createSelector } from 'reselect';

export function selectGTMConsentState(state) {
  return state.gtmConsentState;
}

export const selectYoutubeConsentState = createSelector(
  selectGTMConsentState,
  ({ youtubeVideoConsent }) => !!youtubeVideoConsent,
);

export const selectGA4ConsentState = createSelector(
  selectGTMConsentState,
  ({ ga4Consent }) => !!ga4Consent,
);

export const selectPinterestConsentState = createSelector(
  selectGTMConsentState,
  ({ pinterestConsent }) => !!pinterestConsent,
);

export const selectMatomoConsentState = createSelector(
  selectGTMConsentState,
  ({ matomoConsent }) => !!matomoConsent,
);

export const selectAdformConsentState = createSelector(
  selectGTMConsentState,
  ({ adformConsent }) => !!adformConsent,
);

export const selectFacebookConsentState = createSelector(
  selectGTMConsentState,
  ({ facebookConsent }) => !!facebookConsent,
);
