import React, { Component } from 'react';

import API from '../../tools/API';
import InputTooltip from '../InputTooltip';

import Input from '../../elements/Form/Input';
import Button from '../../elements/Form/Button';
import analyticsTracker from '../../tools/Tracker';

import './Styles.css';

class LoginComponent extends Component {
  state = {
    errors: {},
    loading: false,
    shown: false,
  }

  constructor(props) {
    super(props);

    this._toolTipTimeout = null;
  }

  setShown = (shown, cb) => {
    if (shown && this._toolTipTimeout) {
      clearTimeout(this._toolTipTimeout);
    }
    this.setState({ shown }, cb);
  }

  submitLogin = e => {
    e.preventDefault();

    const errors = {};
    this.setState({
      errors,
    });

    let noUsernameNorPassword = false;

    if (!this.username.element.value) {
      errors.username = true;

      noUsernameNorPassword = true;
    }
    if (!this.password.element.value) {
      errors.password = true;

      noUsernameNorPassword = true;
    }

    if (noUsernameNorPassword) {
      const { toggleSignInUpModal } = this.props;
      toggleSignInUpModal();
    }

    if (Object.keys(errors).length <= 0) {
      API.auth(this.username.element.value, this.password.element.value).then((_response) => {
        this.setState({
          loading: false,
        });
        const { success } = _response;
        if (!success) {
          this.username.element.value = '';
          this.password.element.value = '';

          this.setShown(true, () => {
            this._toolTipTimeout = setTimeout(() => {
              this.setShown(false);

              clearTimeout(this._toolTipTimeout);
            }, 4 * 1000);
          });
        } else {
          analyticsTracker.init().trackEvent('anmeldung', 'submit');
        }
      });

      this.setState({
        loading: true,
      });
    } else {
      this.setState({
        errors,
      });
    }
  }

  render() {
    const {
      toggleSignInUpModal,
      togglePasswordModal,
      setSignUpOverlayActiveSection,
    } = this.props;

    const {
      shown,
      errors,
      loading,
    } = this.state;

    return (
      <div className="LoginComponent" data-loading={loading}>

        <InputTooltip
          style={{
            width: '540px',
            position: 'absolute',
            top: '-21px',
            left: '-570px',
          }}
          shown={shown}
          setShown={this.setShown}
          message="Username/E-Mail und/oder Passwort falsch. Bitte überprüfe deine Angaben. Falls du noch kein Konto bei backmomente.de hast, registriere dich einfach, schnell und kostenlos."
        />

        <form>
          <label className="LoginComponent--inline-label">
            <Input data-error={errors.username} ref={(el) => { this.username = el; }} type="text" placeholder="E-Mail / Benutzername" />
            <a
              className="LoginComponent--link"
              onClick={() => {
                setSignUpOverlayActiveSection('register');
                toggleSignInUpModal();
              }}>
              Konto erstellen
            </a>
          </label>

          <label className="LoginComponent--inline-label">
            <Input data-error={errors.password} ref={(el) => { this.password = el; }} type="password" placeholder="Passwort" />
            <a onClick={togglePasswordModal} className="LoginComponent--link">Passwort vergessen</a>
          </label>

          <label className="LoginComponent--inline-label">
            <Button onClick={this.submitLogin}>Anmelden</Button>
          </label>
        </form>
      </div>
    );
  }
}

export default LoginComponent;
