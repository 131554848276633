import React, { useEffect } from 'react';
// import ReactPixel from 'react-facebook-pixel';

import './Styles.css';

import { Link } from 'react-router-dom';

import Layout from '../../elements/Layout';
import MainMenu from '../MainMenu';

import LoginComponent from '../LoginComponent';
import UserCrownLevel from '../UserCrownLevel';
import cmIcon from '../../images/icon-cm.svg';

function Header(props) {
  const {
    notificationCount,
    userData,
    location,
    toggleMenu,
    toggleSignInUpModal,
  } = props;
  let loginView = null;
  if (userData) {
    loginView = (
      <div className="Header--greeting">
        <UserCrownLevel count={userData.user_level} /> Hallo, <span style={{ width: '4px', display: 'inline-block' }}></span> <Link to="/mein-profil"> {userData.fullName} </Link>
      </div>
    );
  } else {
    loginView = (
      <LoginComponent />
    );
  }
  let cmView = null;
  if (userData && userData.isCommunityManager) {
    cmView = (
      <Link to="/cm" className="Header--cm">
        <img alt="" className="Header--cm-icon" src={cmIcon} />
      </Link>
    );
  }

  useEffect(() => {

  }, []);

  return (
    <header className={!userData ? 'Header' : 'Header logged-in'}>

      {/* {
        getCurrentGtmConsentState()
          ? <p style={{margin: 0, padding: 0, border: 0, height: 0}}>
            <img src="https://track.adform.net/Serving/TrackPoint/?pm=2439907" width="1" height="1" alt="" />
          </p>
          : ``
      } */}

      <Layout>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <Link to="/">
                <h2>backmomente</h2>
              </Link>

              {cmView}
              {loginView}

              <nav className="Header--mobile-menu">
                <ul className="Header--menu">
                  {!userData && (
                    <li>
                      <button onClick={toggleSignInUpModal} className="Header--icon--account">Account</button>
                    </li>
                  )}

                  {userData && (
                    <li>
                      <Link to="/mein-profil" className="Header--icon--account__logged-in">
                        <figure className="Header--icon--figure">
                          <div className="Header--icon--figure-wrapper">
                            <img className="Header--icon--figure--image" src={userData.profileImage || ''} alt="Profilbild" />
                          </div>
                          <span className="MainMenu--notif-count mobile--notif-count">
                            {notificationCount <= 99 ? notificationCount : '99+'}
                          </span>
                        </figure>
                        {userData.fullName}
                      </Link>
                    </li>
                  )}
                  {location?.pathname !== '/suche' && <li>
                    <Link to="/suche">
                      <button className="Header--icon--search">Search</button>
                    </Link>
                  </li>}
                  <li>
                    <button className="Header--icon--burger" onClick={toggleMenu}>Burger</button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Layout>
      <MainMenu />
    </header>
  );
}

export default Header;
