import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import HighlightedButton from '../../elements/HighlightedButton';
import Typography from '../../elements/Typography';

import './Styles.css';

import auroraLogoWebP from '../../images/logo-aurora.webp';
import auroraLogoPNG from '../../images/logo-aurora.png';

import diamantLogoWebP from '../../images/logo-diamant.webp';
import diamantLogoPNG from '../../images/logo-diamant.png';

import goldPuderLogoWebP from '../../images/logo-goldPuder.webp';
import goldPuderLogoPNG from '../../images/logo-goldPuder.png';

import rosenLogoWebP from '../../images/logo-rosen.webp';
import rosenLogoPNG from '../../images/logo-rosen.png';

import gloriaLogoWebP from '../../images/logo-gloria.webp';
import gloriaLogoPNG from '../../images/logo-gloria.png';
import Tracker from '../../tools/Tracker';

class Footer extends Component {
  render() {
    return (
      <footer className="Footer">
        <div className="Footer--newsletter">
          <Typography tag="h2" size="medium">Melde dich jetzt zu unserem Newsletter an:</Typography>
          <Link className="link-unstyled" to="/newsletter" onClick={ () => Tracker.init().trackEvent('Interaktion', 'Footer_Newsletter_Click') }>
            <HighlightedButton>Jetzt abonnieren</HighlightedButton>
          </Link>
        </div>
        <nav className="Footer--social">
          <ul>
            {this.props.socialMediaLinks.facebook && (
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.socialMediaLinks.facebook}
                  className="Footer--social--icon--facebook">
                  Facebook
                </a>
              </li>
            )}

            {this.props.socialMediaLinks.instagram && (
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.socialMediaLinks.instagram}
                  className="Footer--social--icon--instagram">
                  Instagram
                </a>
              </li>
            )}

            {this.props.socialMediaLinks.youtube && (
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.socialMediaLinks.youtube}
                  className="Footer--social--icon--youtube">
                  Youtube
                </a>
              </li>
            )}

            {this.props.socialMediaLinks.pinterest && (
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.socialMediaLinks.pinterest}
                  className="Footer--social--icon--pinterest">
                  pinterest
                </a>
              </li>
            )}

            <li>
              <a
                rel="noopener noreferrer"
                target="_self"
                href="/newsletter"
                className="Footer--social--icon--newsletter">
                  Newsletter
              </a>
            </li>
          </ul>
        </nav>

        {this.props.brandLinks && (
          <nav className="Footer--brands">
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.brandLinks.aurora}
                  className="Footer--brands--icon--aurora">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={auroraLogoWebP}
                    />
                    <img alt="Logo für Aurora" src={auroraLogoPNG} />
                  </picture>
                </a>
              </li>

              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.brandLinks.diamant}
                  className="Footer--brands--icon--diamant">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={diamantLogoWebP}
                    />
                    <img alt="Logo für Diamant" src={diamantLogoPNG} />
                  </picture>
                </a>
              </li>

              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.brandLinks.goldpuder}
                  className="Footer--brands--icon--gold">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={goldPuderLogoWebP}
                    />
                    <img alt="Logo für Goldpuder" src={goldPuderLogoPNG} />
                  </picture>
                </a>
              </li>

              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={this.props.brandLinks.rosenmehl}
                  className="Footer--brands--icon--rosenmehl">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={rosenLogoWebP}
                    />
                    <img alt="Logo für Goldpuder" src={rosenLogoPNG} />
                  </picture>
                </a>
              </li>
            </ul>
          </nav>
        )}

        <nav className="Footer--footer-nav">
          {this.props.footerMenuData && (
            <ul>
              {this.props.footerMenuData.map((item, index) => (
                <Fragment key={index}>
                  <li>
                    <a href={item.url}>{item.title}</a>
                  </li>

                </Fragment>
              ))}
            </ul>
          )}

        </nav>

        <p className="Footer--tagline">
          &copy; Aurora Mühlen GmbH - Trettaustraße 49 – D-21107 Hamburg
        </p>
      </footer>
    );
  }
}

export default Footer;
