import React, { Component } from 'react';
import cx from 'classnames';

import './Styles.css';

class ButtonDistorted extends Component {
  render() {
    const { className, children, ...newProps } = this.props;
    return (
      <button className={cx(
        'ButtonDistorted', className || '',
      )} onClick={this.handleClick} {...newProps}>{children}</button>
    );
  }

  handleClick = () => {
    if (this.props.handleClick) this.props.handleClick();
    if (this.props.href) window.location.href = this.props.href; // this.props.history.push(this.props.href);
  }
}

export default ButtonDistorted;
