import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import store from './redux/store';

import API from './tools/API';
import Bootstrap from './tools/Bootstrap';

import AppView from './AppView';

import './App.css';

import AnalyticsTracker from './tools/Tracker';
AnalyticsTracker.init();

class App extends Component {
  componentDidMount() {
    API.getInitData();
    API.getFilters();
    API.getChannels();
    Bootstrap.run();
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <AppView />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
