import { produce } from 'immer';
import { DOUGH_LOADING_ERROR, SET_ALL_GRUNDTEIGE, SET_DOUGH, SET_DOUGHS, SET_DOUGH_RECIPES, START_DOUGH_LOADING } from './actions';

const initialState = {
  byId: {},
  recipieById: {},
  allIds: [],
  allRecipiesIds: [],
  loading: false,
  recipeLoading: false,
  error: null,
  data: null,
  allBaseDoughs: null,
};

export default function baseDoughPagesReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_DOUGH:
        // eslint-disable-next-line no-case-declarations
        const page = action.payload;
        if (!page.id) return state;
        draft.byId[page.id] = page;
        draft.allIds = [...new Set([...draft.allIds, page.id])];
        draft.loading = false;
        break;

      case SET_DOUGH_RECIPES:
        // eslint-disable-next-line no-case-declarations
        const recipies = action.payload;
        if (!recipies) return state;
        recipies.forEach((recipie) => {
          draft.recipieById[recipie.id] = recipie;
        });
        draft.allRecipiesIds = [...new Set([...draft.allRecipiesIds, ...recipies.map(({ id }) => id)])];
        draft.recipeLoading = false;
        break;

      case SET_DOUGHS:
        // eslint-disable-next-line no-case-declarations
        const pages = action.payload;
        if (!pages) return state;
        pages.forEach((page) => {
          draft.byId[page.id] = page;
        });
        draft.allIds = [...new Set([...draft.allIds, ...pages.map(({ id }) => id)])];
        draft.loading = false;
        break;

      case SET_ALL_GRUNDTEIGE:
        draft.allBaseDoughs = action.payload;
        break;

      case START_DOUGH_LOADING:
        draft.loading = true;
        break;

      case DOUGH_LOADING_ERROR:
        draft.error = action.error;
        break;

      default:
        return state;
    }
  });
}
