import React, { Component } from 'react';
import cx from 'classnames';
import './Styles.css';

class CommentOverlayRatingInput extends Component {
  state = {
    selected: null,
    highlight: null,
  }

  render() {
    const { defaultRating, disabled, userData } = this.props;
    const { highlight, selected } = this.state;
    const rating = selected || defaultRating;
    return (
      <div
        className={cx('CommentOverlayRatingInput', {
          'CommentOverlayRating--disabled': disabled,
        })}
        onMouseOut={this.mouseOut}
      >
        {[1, 2, 3, 4, 5].map((item, i) => (
          <div
            key={i}
            onMouseOver={this.mouseOver.bind(this, i + 1)}
            onClick={this.handleClick.bind(this, i + 1)}
            className={cx('CommentOverlayRatingInput--star', {
              'CommentOverlayRatingInput--interactable': userData,
              'CommentOverlayRatingInput--star__filled': i <= (highlight || rating) - 1,
            })}
          >
            CommentOverlayRatingInput--star
          </div>
        ))}
      </div>
    );
  }

  mouseOut = () => {
    const { userData } = this.props;
    if (!userData) {
      return;
    }
    this.setState({
      highlight: null,
    });
  }

  mouseOver = (index) => {
    const { userData } = this.props;
    if (!userData) {
      return;
    }
    this.setState({
      highlight: index,
    });
  }

  handleClick = (index) => {
    const { userData } = this.props;
    if (!userData) {
      return;
    }
    this.setState({
      selected: index,
    });
    this.props.onSelect(index);
  }
}

export default CommentOverlayRatingInput;
