import React from 'react';
import classNames from 'classnames';

import Layout from '../../Layout';

function Container(props) {
  return (
    <Layout>
      <div className={classNames(props.addClass, 'container-fluid')}>
        {props.children}
      </div>
    </Layout>
  );
}

export default Container;
