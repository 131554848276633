import React, { Component } from 'react';
import './Styles.css';

import Modal from '../Modal';
import Layout from '../../elements/Layout/Container';
import Headline from '../Headline';
import Button from '../Button/Distorted';
import ModalCloseButton from '../Modal/CloseButton';

class MessageOverlay extends Component {
  render() {
    const {
      primaryLabel = 'Ok',
      isOpen,
      headline,
      children,
      handleClose,
      closeButton,
      disabled,
      isWider = false,
    } = this.props;
    return (
      <Modal isOpen={isOpen} onRequestClose={handleClose} className="MessageOverlay">
        <Layout>
          <div className="row">
            <div className={isWider ? 'col-lg-10 col-lg-offset-1' : 'col-lg-6 col-lg-offset-3'}>
              <div className="MessageOverlay--inner">
                {closeButton && (
                  <ModalCloseButton
                    onClick={handleClose}
                    style={{
                      top: '12px',
                      right: '22px',
                      width: '22px',
                      height: '22px',
                    }}
                  />
                )}
                {headline && (
                  <Headline>{headline}</Headline>
                )}
                {children}
                <footer className="MessageOverlay--footer">
                  <Button onClick={this.handlePrimaryClick} data-inline="true" disabled={disabled}>{primaryLabel}</Button>
                </footer>
              </div>
            </div>
          </div>
        </Layout>
      </Modal>
    );
  }

  handlePrimaryClick = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
    if (this.props.handleSubmit) {
      this.props.handleSubmit();
    }
  }
}

export default MessageOverlay;
