import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';

import RecipeRating from '../../RecipeRating';
import Image from '../../Image';
import DropzoneWrapper from '../../DropzoneWrapper';
import UserCrownLevel from '../../UserCrownLevel';
import Button from '../../../elements/Button/Distorted';
import Textarea from '../../../elements/Form/Textarea';

import '../Style.css';

export default class SingleUserComment extends Component {
  state = {
    droppedImageID: null,
  }

  render() {
    const {
      id,
      author_avatar_urls: authorAvatarUrls,
      meta_comment_profile_image: metaCommentProfileImage,
      meta_comment_user_level: metaCommentUserLevel,
      meta_comment_username: metaCommentUsername,
      date,
      rating,
      content: { rendered: renderedContent },
      comment_media_attachment_url: commentMediaAttachmentUrl,
      depth,
      userData,
      replyModalOpen,
      // replyText,
      handleAttachedReplyPicture,
      // showReplyModal,
      setReply,
      // sendReply,
      deleteComment,
      // toggleSignInUpModal,
    } = this.props;

    const commentOverlay = `url(${metaCommentProfileImage || authorAvatarUrls[Object.keys(authorAvatarUrls)[0]]})`;

    return (
      <li
        className={cx(
          'CommentOverlay--comment',
          {
            [`CommentOverlay--comment__indent${depth}`]: true,
          },
        )}>
        <Link
          to={metaCommentUsername ? `/benutzer/${metaCommentUsername}` : ''}
          className="CommentOverlay--comment-avatar"
          style={{ backgroundImage: commentOverlay }}
        />
        <div className="CommentOverlay--comment-body">
          <div className="CommentOverlay--comment-heading">
            <div className="CommentOverlay--comment-author-date">
              <UserCrownLevel count={metaCommentUserLevel} />
              <h3 className="CommentOverlay--comment-title">
                <Link to={metaCommentUsername ? `/benutzer/${metaCommentUsername}` : ''}>{metaCommentUsername || '[USER GELÖSCHT]'}</Link>
                <span className="RecipeRating--container">
                  {depth === 0 && <RecipeRating rating={rating} />}
                </span>
              </h3>
              <span className="CommentOverlay--comment-date">{moment(date).format('LLLL')}</span>
            </div>
          </div>
          <span className="CommentOverlay--comment-text" dangerouslySetInnerHTML={{ __html: renderedContent }} />
          {
            commentMediaAttachmentUrl !== '' &&
            <Image
              wrapperClassName="RecipeRating--imageAttachment"
              fallbackSrc={commentMediaAttachmentUrl}
            />
          }
          <div className="CommentOverlay--replyInput">
            <DropzoneWrapper
              customStyle={{ display: replyModalOpen !== id ? 'none' : 'block' }}
              onDrop={imageToBeUploaded => {
                handleAttachedReplyPicture(imageToBeUploaded);
                this.setState({ droppedImageID: imageToBeUploaded.name || imageToBeUploaded.preview });
              }}/>
            <Textarea data-spaced="true" data-hidden={replyModalOpen !== id} placeholder="Antwort eingeben" onChange={setReply} data-dark="true" data-noshadow="true" />
          </div>
          <div style={{ alignSelf: 'flex-end' }}>
            {
              userData &&
              userData.isCommunityManager &&
              <Button
                style={{ marginRight: '10px' }}
                data-inline="true"
                onClick={() => deleteComment(id)}>
                Löschen
              </Button>
            }
            {/* <Button
              data-inline
              disabled={replyModalOpen === id && replyText === ''}
              onClick={() => {
                if (!userData) {
                  return toggleSignInUpModal()
                }
                replyModalOpen === id ? sendReply(this.state.droppedImageID) : showReplyModal(id)
              }}>
              {replyModalOpen === id ? 'Absenden' : 'Antworten'}
            </Button> */}
          </div>
        </div>
      </li>
    );
  }
}
