import { connect } from 'react-redux';
import {
  toggleSignInUpModal,
  togglePasswordModal,
  setSignUpOverlayActiveSection,
} from '../../redux/modules/ui';

import LoginComponent from './presentation';

export default connect(() => ({}), {
  toggleSignInUpModal,
  togglePasswordModal,
  setSignUpOverlayActiveSection,
})(LoginComponent);
