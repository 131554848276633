// src/components/SignInUpOverlay/index.js
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Presentation from './presentation';

import {
  toggleSignInUpModal,
  togglePasswordModal,
  setSignUpOverlayActiveSection,
  setSigninupModalAllFieldsRequired,
  setGewinnSpielAnswer,
} from '../../redux/modules/ui';
import { parse } from '../../tools/queryString';

const SignInUpOverlayContainer = (props) => {
  const location = useLocation();
  const query = parse(location.search);
  const invitationToken = query.ref;

  return (
    <Presentation
      {...props}
      location={location}
      invitationToken={invitationToken}
    />
  );
};

const mapStateToProps = (state) => ({
  gewinnSpielAnswer: state.ui.gewinnSpielAnswer,
  signinupModalAllFieldsRequired: state.ui.signinupModalAllFieldsRequired,
  signUpOverlayActiveSection: state.ui.signUpOverlayActiveSection,
  signinupModalAllowClose: state.ui.signinupModalAllowClose,
  userData: state.content.userData,
  authInProgress: state.content.authInProgress,
  open: state.ui.signinupModalOpen,
  miscSettings: state.content.miscSettings,
  errorMessages: state.content.errorMessages,
});

const mapDispatchToProps = {
  toggleSignInUpModal,
  togglePasswordModal,
  setSignUpOverlayActiveSection,
  setSigninupModalAllFieldsRequired,
  setGewinnSpielAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInUpOverlayContainer);
