import { combineReducers } from 'redux';
import ui from './ui';
import content from './content';
import commentOverlay from './comment-overlay';
import baseDoughPages from './base-dough-pages/reducer';
import gtmConsentState from './gtm-data-layer/reducer';

export default combineReducers({
  ui,
  content,
  commentOverlay,
  baseDoughPages,
  gtmConsentState,
});
