import classNames from 'classnames';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

const P = styled('p')`
  font-size: 16px;
  line-height: 1.5em;
  ${props => props.center && 'text-align: center;'}
  ${props => props.bold && 'font-weight: bold;'}
  ${props => props.size === 'big' && 'font-size: 20px;'}
  & > p {
    margin-bottom: 1em;
  }
`;

function Typography({
  children,
  className = '',
  center = false,
  bold = false,
  size = 'big', // small, medium, big, bigger
  tag = 'p',
  variant = '',
  dangerousInnerHTML = false,
}) {
  if (!variant) variant = tag;
  className = classNames('typography', className);
  const isHeadline = useMemo(() => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant), [variant]);

  if (isHeadline) {
    className = classNames(className, 'Headline', {
      'is-big-headline': size === 'big',
      'is-middle-headline': size === 'middle',
      'is-small-headline': size === 'small',
      'is-really-big-headline': size === 'bigger',
    });
  }
  if (dangerousInnerHTML) return (<P as={ tag } { ...{ center, bold, size, className } } dangerouslySetInnerHTML={ { __html: children } } />);
  return (<P as={ tag } { ...{ center, bold, size, className } }>{ children }</P>);
}

export default memo(Typography);
