import React, { Component } from 'react';
import './Styles.css';

import ReactModal from 'react-modal';

class Modal extends Component {
  render() {
    return (
      <ReactModal {...this.props}>
        {this.props.children}
      </ReactModal>
    );
  }
}

export default Modal;
