import React from 'react';
import cx from 'classnames';

import './Styles.css';

const UserCrownLevel = ({ count }) => {
  let crownClassName = 'UserCrownLevel--noCrown';
  if (count >= 20) {
    crownClassName = 'UserCrownLevel--goldCrown';
  } else if (count >= 10) {
    crownClassName = 'UserCrownLevel--silverCrown';
  } else if (count >= 5) {
    crownClassName = 'UserCrownLevel--bronzeCrown';
  }
  return (
    <span className={cx('UserCrownLevel--crown', crownClassName)} />
  );
};

export default UserCrownLevel;
