import React, { Component } from 'react';
import cx from 'classnames';
import './Styles.css';

class FormInput extends Component {
  render() {
    const { isBig, ...newProps } = this.props;
    return (
      <input
        ref={(el) => { this.element = el; }}
        className={cx('FormInput', isBig ? 'big-input' : '')}
        {...newProps} />
    );
  }
}

export default FormInput;
