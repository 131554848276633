import React, { Component } from 'react';
import cx from 'classnames';
import resizeImage from 'resize-image';
import Dropzone from 'react-dropzone';

import { dataURLtoFile } from '../../tools/utils';

import './Style.css';

export default class DropzoneWrapper extends Component {
  state = {
    imagePreview: null,
  }

  _onDrop = images => {
    const imageToBeUploaded = images[0];
    if (!imageToBeUploaded) {
      return;
    }
    const { onDrop, maxImageWidth = 1024 } = this.props;
    const { preview: imagePreview } = imageToBeUploaded;
    this.setState({ imagePreview });

    const { name: originalFileName } = imageToBeUploaded;

    const onImageResize = newResizedFile => {
      const blobLocalURL = URL.createObjectURL(newResizedFile);
      newResizedFile.preview = blobLocalURL;
      onDrop(newResizedFile, () => {
        this.setState({ imagePreview });
      });
    };

    const onImageLoad = () => {
      const { width, height } = img;
      if (width > maxImageWidth) {
        const newWidth = maxImageWidth;
        const widthDelta = newWidth / width;
        const newHeight = height * widthDelta;
        const data = resizeImage.resize(img, newWidth, newHeight);
        dataURLtoFile(data, originalFileName).then(onImageResize);
      } else {
        onDrop(imageToBeUploaded, () => {
          this.setState({ imagePreview });
        });
      }
    };

    const img = document.createElement('img');
    img.onload = onImageLoad;
    img.src = imageToBeUploaded.preview;
  }

  render() {
    const {
      isInline = false,
      className,
      customStyle,
      hideImg = true,
      disabled = false,
      onCancel,
    } = this.props;
    const { imagePreview } = this.state;
    const backgroundStyle = {
      backgroundImage: `url(${hideImg && imagePreview})`,
    };
    return (
      <Dropzone
        className={cx({
          Dropzone: true,
          'Dropzone--success': hideImg && imagePreview,
          'Dropzone--inline': isInline,
          'Dropzone--disabled': disabled,
          [className]: className,
        })}
        style={customStyle ? { ...backgroundStyle, ...customStyle } : backgroundStyle}
        disabled={disabled}
        onDrop={this._onDrop}
        accept="image/*"
        multiple={false}
      >
        <button
          className={cx('Dropzone__closeImage', {
            'Dropzone__closeImage-visible': imagePreview,
          })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ imagePreview: null });
            onCancel && onCancel();
          }} />
      </Dropzone>
    );
  }
}
