import React from 'react';
import cx from 'classnames';

import './Styles.css';

function ModalCloseButton(props) {
  const { className, ...newProps } = props;
  return (
    <button
      className={cx('ModalCloseButton', { [className]: true })}
      {...newProps}>
      Schließen
    </button>
  );
}

export default ModalCloseButton;
