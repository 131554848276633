import React, { Component } from 'react';

// import ModalCloseButton from '../../elements/Modal/CloseButton';
import MessageOverlay from '../../elements/MessageOverlay';
import Input from '../../elements/Form/Input';
import { validateEmail } from '../../tools/utils';

import API from '../../tools/API';

const MESSAGE_INITIAL = 'Gerne senden wir Dir ein neues Passwort an Deine hinterlegte E-Mail-Adresse.';

class RequestNewPasswordOverlay extends Component {
  state = {
    inputValue: '',
  }

  _handleSubmit = () => {
    const { inputValue } = this.state;
    if (!validateEmail(inputValue)) {
      return;
    }
    API.resetPassword(inputValue).then(() => {
      this.setState({
        inputValue: '',
      });
    });
  }

  _handleKeyPress = e => {
    if (e.keyCode === 13) {
      this._handleSubmit();
    }
  }

  _handleOnChange = e => {
    this.setState({ inputValue: e.target.value });
  }

  render() {
    const { resetPasswordModalOpen, togglePasswordModal } = this.props;
    const { inputValue } = this.state;

    return (
      <MessageOverlay
        isOpen={resetPasswordModalOpen}
        headline="Passwort vergessen?"
        handleClose={togglePasswordModal}
        closeButton
        handleSubmit={this._handleSubmit}>
        <p>{MESSAGE_INITIAL}</p>
        <br /><br />
        <Input
          data-noshadow="true"
          data-lowercase="true"
          data-block="true"
          data-dark="true"
          value={inputValue}
          onChange={this._handleOnChange}
          onKeyDown={this._handleKeyPress}
          placeholder="Ihre E-Mail-Adresse" />
      </MessageOverlay>
    );
  }
}

export default RequestNewPasswordOverlay;
