import React, { useState, useEffect } from 'react';
import Modal from '../../elements/Modal';
import ModalCloseButton from '../../elements/Modal/CloseButton';
import Button from '../../elements/Form/Button';
import Label from '../../elements/Form/Label';
import Input from '../../elements/Form/Input';
import FormErrorMessage from '../../elements/Form/ErrorMessage';
import Headline from '../../elements/Headline';
import API from '../../tools/API';
import './Styles.css';

const SignInUpOverlay = ({
  open,
  toggleSignInUpModal,
  location,
  invitationToken,
  setSignUpOverlayActiveSection,
  errorMessages,
}) => {
  const [state, setState] = useState({
    errors: {},
    errorMessages: {},
    loading: true,
    success: false,
    loginErrorShown: false,
    isRegisterSubmitLoading: false,
    referrerUsername: '',
    has8Chars: false,
    hasOneUpperChar: false,
    hasOneLowerChar: false,
    hasNumber: false,
    hasNoSpecialChars: false,
    screenWidth: window.innerWidth,
    registerAdditionalInfoSectionHeight: null,
    registerAdditionalInfoSectionOpen: false,
  });

  // Effect for managing the modal state based on `open` prop
  useEffect(() => {
    document.documentElement.style.overflowY = open ? 'hidden' : 'initial';
  }, [open]);

  // Handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setState(currentState => ({ ...currentState, screenWidth: window.innerWidth }));
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // This function could handle form submissions or other actions
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Example: API call logic here
  };

  // Conditional rendering based on `open` and other state variables
  if (!open) {
    return null;
  }

  return (
    <Modal isOpen={open} onRequestClose={() => toggleSignInUpModal()}>
      <ModalCloseButton onClick={() => toggleSignInUpModal()} />
      <div className="modal-content">
        <Headline>Sign In or Sign Up</Headline>
        <form onSubmit={handleSubmit}>
          <Label>
            Username
            <Input type="text" placeholder="Enter username" />
          </Label>
          <Label>
            Password
            <Input type="password" placeholder="Password" />
          </Label>
          {state.errors.general && (
            <FormErrorMessage>
              {state.errorMessages.general}
            </FormErrorMessage>
          )}
          <Button type="submit">Submit</Button>
        </form>
      </div>
    </Modal>
  );
};

export default SignInUpOverlay;
