// import moment from 'moment';
import { produce } from 'immer';
import API from '../../tools/API';

export const HOMEPAGE_SET_DATA = 'content/homepage-set-data';
export const SET_INIT_DATA = 'content/set-init-data';
export const AUTH_USER = 'content/auth-user';
export const SET_FILTERS = 'content/set-filters';
export const SET_CHANNELS = 'content/set-channels';
export const SET_NOTIFICATION_COUNT = 'content/set-notification-count';
export const SET_CACHED_TEASERS = 'content/set-cached-teasers';
export const SAVE_SCROLL_POSITION = 'content/save-scroll-position';
export const SET_PREVIOUS_PAGE_PATHNAME = 'content/set-previous-page-pathname';
export const SET_CURRENT_PAGE_PATHNAME = 'content/set-current-page-pathname';
export const AUTH_IN_PROGRESS = 'content/auth-in-progress';
export const AUTH_FINISHED = 'content/auth-finished';

const initialState = {
  homepageData: {},
  menuData: [],
  footerMenuData: [],
  socialMediaLinks: [],
  brandLinks: [],
  userData: null,
  miscSettings: {},
  errorMessages: {},
  filters: [],
  channels: [],
  notificationCount: 0,
  authInProgress: false,
  allowedToSeeKaffeeklatsch: true,
  cachedTeasersPage: null,
  cachedTeasers: [],
  scrollPosition: 0,
  previousPagePathname: null,
  currentPagePathname: null,
};

function mapMenuData(menuData) {
  return menuData.map(v => {
    if (v.title === 'Rezepte') {
      return {
        title: v.title,
        icon: v.icon,
        url: v.url,
        menu: [
          { title: 'Empfehlungen', url: '/rezepte/empfehlungen' },
          { title: 'Neueste', url: '/rezepte/neueste' },
          { title: 'Filter', url: '/rezepte/filter' },
          { title: 'Upload', url: '/upload' },
        ],
      };
    } else {
      return v;
    }
  });
}

const CATEGORID_TO_SORTIDX_MAP = new Map([
  [37, 0],
  [42, 1],
  [43, 2],
  [45, 3],
  [41, 4],
  [39, 5],
  [40, 6],
  [36, 7],
  [44, 8],
  [38, 9],
  [31, 10],
  [32, 11],
  [33, 12],
  [34, 13],
  [35, 14],
]);
const FLOURID_TO_SORTIDX_MAP = new Map([
  [63, 0],
  [64, 1],
  [69, 2],
  [72, 3],
  [71, 4],
  [74, 5],
]);
const DIFFICULTYID_TO_SORTIDX_MAP = new Map([
  [5, 0],
  [14, 1],
  [20, 2],
]);
const DURATIONID_TO_SORTIDX_MAP = new Map([
  [59, 0],
  [61, 1],
  [60, 2],
]);

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CACHED_TEASERS: {
      return Object.assign({}, state, {
        cachedTeasersPage: action.cachedTeasersPage,
        cachedTeasers: action.cachedTeasers,
      });
    }
    case SAVE_SCROLL_POSITION: {
      return Object.assign({}, state, {
        scrollPosition: action.scrollPosition,
      });
    }
    case SET_PREVIOUS_PAGE_PATHNAME: {
      return Object.assign({}, state, {
        previousPagePathname: action.pathname,
      });
    }
    case SET_CURRENT_PAGE_PATHNAME: {
      return Object.assign({}, state, {
        currentPagePathname: action.pathname,
      });
    }
    case AUTH_IN_PROGRESS: {
      return Object.assign({}, state, { authInProgress: true });
    }
    case AUTH_FINISHED: {
      return Object.assign({}, state, { authInProgress: false });
    }
    case HOMEPAGE_SET_DATA:
      return produce(state, draft => {
        draft.homepageData = JSON.parse(JSON.stringify(action.homepageData));
      });

    case SET_INIT_DATA: {
      return Object.assign({}, state, {
        menuData: mapMenuData(action.initData.menu),
        footerMenuData: action.initData.footerMenu,
        socialMediaLinks: action.initData.socialMedia,
        brandLinks: action.initData.brands,
        miscSettings: action.initData.misc,
        errorMessages: action.initData.errorMessages,
      });
    }
    case AUTH_USER: {
      return Object.assign({}, state, {
        userData: action.userData,
      });
      // return Object.assign({}, state, {
      //   userData: Object.assign({}, action.userData, {
      //     sweetGreetings: [
      //       // {
      //       //   text: 'this is my text from this week',
      //       //   timestamp: moment().toISOString()
      //       // },
      //       // {
      //       //   text: 'this is my text from 1 week ago',
      //       //   timestamp: moment().subtract(1, 'weeks').toISOString()
      //       // },
      //       // {
      //       //   text: 'this is my text from 2 weeks ago',
      //       //   timestamp: moment().subtract(2, 'weeks').toISOString()
      //       // },
      //       {
      //         text: 'this is my text from 3 weeks ago',
      //         timestamp: moment().toISOString()
      //       }
      //     ]
      //   })
      // })
    }
    case SET_NOTIFICATION_COUNT: {
      return Object.assign({}, state, {
        notificationCount: action.count,
      });
    }
    case SET_FILTERS:
      return produce(state, draft => {
        const filters = JSON.parse(JSON.stringify(action.filters.filters));
        filters.forEach(filter => {
          filter.options.sort((a, b) => {
            switch (filter.title) {
              case 'Schwierigkeit':
                return DIFFICULTYID_TO_SORTIDX_MAP.get(a.id) - DIFFICULTYID_TO_SORTIDX_MAP.get(b.id);
              case 'Kategorie':
                return CATEGORID_TO_SORTIDX_MAP.get(a.id) - CATEGORID_TO_SORTIDX_MAP.get(b.id);
              case 'Mehle':
                return FLOURID_TO_SORTIDX_MAP.get(a.id) - FLOURID_TO_SORTIDX_MAP.get(b.id);
              case 'Zeitaufwand':
                return DURATIONID_TO_SORTIDX_MAP.get(a.id) - DURATIONID_TO_SORTIDX_MAP.get(b.id);
              default:
                return 0;
            }
          });
        });
        draft.filters = filters;
      });
    case SET_CHANNELS: {
      const channels = action.channels;
      return Object.assign({}, state, { channels });
    }
    default:
      return state;
  }
};

export function setCachedTeasers({ page: cachedTeasersPage, teasers: cachedTeasers } = {}) {
  return {
    type: SET_CACHED_TEASERS,
    cachedTeasersPage,
    cachedTeasers,
  };
}

export function saveScrollPosition(scrollPosition) {
  return {
    type: SAVE_SCROLL_POSITION,
    scrollPosition,
  };
}

export function setPreviousPagePathname(pathname) {
  return {
    type: SET_PREVIOUS_PAGE_PATHNAME,
    pathname,
  };
}

export function setCurrentPagePathname(pathname) {
  return {
    type: SET_CURRENT_PAGE_PATHNAME,
    pathname,
  };
}

export function setHomepageData(homepageData) {
  return {
    type: HOMEPAGE_SET_DATA,
    homepageData,
  };
}

export function setInitData(initData) {
  return {
    type: SET_INIT_DATA,
    initData,
  };
}

export function authInProgress() {
  return {
    type: AUTH_IN_PROGRESS,
  };
}

export function authFinished() {
  return {
    type: AUTH_FINISHED,
  };
}

export const authUser = (userData) => (dispatch, getState) => {
  dispatch({
    type: AUTH_USER,
    userData,
  });
  dispatch(fetchNotificationCount());
};

export function setFilters(filters) {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setChannels(channels) {
  return {
    type: SET_CHANNELS,
    channels,
  };
}

export const fetchNotificationCount = () => (dispatch, getState) => {
  API.getUnreadNotificationCount().then(response => {
    if (response.success) {
      dispatch({
        type: SET_NOTIFICATION_COUNT,
        count: response.unread_count,
      });
    }
  });
};
