import debounce from 'lodash.debounce';
import { useEffect, useRef } from 'react';
import { trackEvent } from '../tools/Tracker';
import { useLocation } from './useLocation';

const SCROLL_POINTS = [0.25, 0.50, 0.75, 1];

export function useScrollAnalytics() {
  const { pathname } = useLocation();
  const measurements = useRef(SCROLL_POINTS);

  useEffect(() => {
    measurements.current = SCROLL_POINTS;
  }, [pathname]);

  useEffect(() => {
    const scrollListener = debounce(() => {
      const docHeight = +document.documentElement.scrollHeight;
      const scrollPositionBottom = +window.pageYOffset + +window.innerHeight;
      const scrolled = scrollPositionBottom / docHeight;

      measurements.current.forEach(m => {
        if (scrolled < m - 0.01) return;
        measurements.current = measurements.current.filter(v => v !== m);
        trackEvent('Verweilen', `Scrolltiefe > ${m * 100}%`, `Scrolltiefe: > ${m}% für ${pathname}`, { value: m * 100 });
      });
    }, 50);
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, [measurements, pathname]);
}
