import React, { Component, Fragment } from 'react';
import './Styles.css';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { toggleSignInUpModal } from '../../redux/modules/ui';
import Button from '../../elements/Button/Distorted';
import * as clipboard from 'clipboard-polyfill';

class Copy extends Component {
  state = {
    copyLinkText: 'Empfehlungslink kopieren',
  }

  copyReferral = () => {
    const { toggleSignInUpModal, userData } = this.props;
    const { copyLinkText } = this.state;
    if (!userData) {
      toggleSignInUpModal();
      return;
    }
    const uid = userData.invitation_token;
    const linkToCopy = `https://www.backmomente.de/register?ref=${uid}`;
    clipboard.writeText(linkToCopy).then(() => {
      this.setState({ copyLinkText: 'Link kopiert!' });
      const timeout = setTimeout(() => {
        this.setState({ copyLinkText });
        clearTimeout(timeout);
      }, 2.5 * 1000);
    });
  }

  render() {
    const { theHtml, children, className, userData } = this.props;
    const { copyLinkText } = this.state;
    const cx = classnames(className, 'Copy');
    if (theHtml) {
      let html = theHtml;
      let copyButton = null;
      if (theHtml.indexOf('register?ref=[uid]') !== -1) {
        if (userData) {
          html = theHtml.replace(/register\?ref=\[uid]/g, `register?ref=${userData.invitation_token}`);
          copyButton = (
            <Button
              className="Copy--copyReferral"
              data-inline="true"
              onClick={this.copyReferral}>{copyLinkText}
            </Button>
          );
        } else {
          html = theHtml.replace(/<a href="https:\/\/www.backmomente\.de\/register\?ref=\[uid]">.*<\/a>/, '******');
          copyButton = (
            <Button
              className="Copy--copyReferral"
              data-inline="true"
              onClick={this.copyReferral}>Anmelden um Freunde zu werben
            </Button>
          );
        }
      }
      return (
        <Fragment>
          <div className={cx} dangerouslySetInnerHTML={{ __html: html }}/>
          {copyButton}
        </Fragment>
      );
    }
    return <div className={cx}>{children}</div>;
  }
}

export default connect(({ content: { userData } }) => ({
  userData,
}), {
  toggleSignInUpModal,
})(Copy);
