import React from 'react';
import cx from 'classnames';

import './Styles.css';

function RecipeDuration(props) {
  return (
    <div className={cx('RecipeDuration', {
      teaserCard: props.isInTeaserCard,
    })}>
      {props.children}
    </div>
  );
}

export default RecipeDuration;
