import { connect } from 'react-redux';
import withRouter from '../../hooks/withRouter';

import RecipeTeaser from './presentation';

import { authUser } from '../../redux/modules/content';
import { toggleSignInUpModal } from '../../redux/modules/ui';

import {
  setCommentOverlayOpen,
  setCommentRecipe,
} from '../../redux/modules/comment-overlay';

function mapStateToProps(state) {
  return {
    userData: state.content.userData,
    viewportWidth: state.ui.viewportWidth,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    authUser: () => dispatch(authUser()),
    toggleSignInUpModal: () => dispatch(toggleSignInUpModal()),
    setCommentOverlayOpen: (isOpen) => dispatch(setCommentOverlayOpen(isOpen)),
    setCommentRecipe: (recipe) => dispatch(setCommentRecipe(recipe)),
    push: (path) => ownProps.history.push(path), // Use history from ownProps
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecipeTeaser));
