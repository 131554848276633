import React, { useState } from 'react';
import './Styles.css';

const CustomSelect = ({ options, placeholder = 'Select an option...', onValueChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = option => {
    setSelectedOption(option); // Update the internal state
    toggleOptions();

    // If onValueChange is provided, call it with the newly selected option
    if (onValueChange) {
      onValueChange(option);
    }
  };

  return (
    <div className="custom-select">
      <div
        className={`selected-option ${!selectedOption ? 'placeholder' : ''} ${isOpen ? 'open' : ''}`}
        onClick={toggleOptions}
      >
        {selectedOption || placeholder}
        <i></i>
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option, index) => (
            <div
              key={index}
              className="option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
