import API from '../../../tools/API';

/**
 * short access function to deep access object values by path string with fallback
 * @param {any} obj
 * @param {string} path
 * @param {any} fallback
 * @param {boolean} strict By default, a nullish value will return the fallback value (e.g. 0, '', false, etc.).
 *                         Set `strict`to true to return fallback only when undefined (use this to directly access simple fields like booleans, etc.).
 * @returns any
 */
export function xs(obj, path, fallback = '', strict = false) {
  if (typeof path === 'string') path = path.split('.');
  const part = path.shift();
  if (strict && obj[part] === undefined) return fallback;
  if (!strict && !obj[part]) return fallback;
  if (path.length === 0) return obj[part];
  return xs(obj[part], path, fallback);
}

export async function fetchWPImageById(id) {
  if (!id) return {};
  try {
    const response = await API.fetchMediaById(id);
    return (response && response[0]) || {};
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function fetchWpV2Data(query, defaultValue = undefined) {
  if (!query) return defaultValue;

  try {
    const response = await API.constructedFetch(`wp/v2/${query}`);

    // console.log({ query, response });

    return response;
  } catch (error) {
    console.log(`Could not fetch data from API for query: ${query}`, error);
    return defaultValue;
  }
}

export async function fetchFloursViewData(flourIds) {
  if (!flourIds && !Array.isArray(flourIds)) return [];
  const response = await fetchWpV2Data(`mehl?include=${flourIds.join(',')}&acf_format=standard`, []);

  return response
    .filter(flour => !!flour && !!flour.acf && !!flour.acf.brands && !!flour.acf.brands[0]);
}

export function stripHtmlTags(text) {
  if (!text) return '';
  const div = document.createElement('div');
  div.innerHTML = text;
  return div.textContent || div.innerText || '';
}
