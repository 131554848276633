import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import './Styles.css';

// import API from '../../tools/API';

import Layout from '../../elements/Layout';

import logoutIcon from '../../images/if_sign-out_1608410.svg';

function MainMenu({
  menuOpen,
  submenuOpen,
  menuData,
  userData,
  toggleMenu,
  toggleSubmenu,
  location,
  notificationCount,
  allowedToSeeKaffeeklatsch,
  toggleSignInUpModal,
}) {
  let activeItem;
  if (menuData) {
    activeItem = menuData.find(item => {
      if (item.url === location?.pathname) {
        return true;
      }
      return item.menu && item.menu.find(subItem => subItem.url === location?.pathname);
    });
    if (!activeItem) {
      if (location?.pathname.includes('selbstgebacken')) {
        activeItem = menuData.find(item => item.url === '/selbstgebacken');
      } else if (location?.pathname.includes('rezepte') || location?.pathname.includes('rezept')) {
        activeItem = menuData.find(item => item.url === '/rezepte');
      } else if (location?.pathname.includes('forum')) {
        activeItem = menuData.find(item => item.url === '/forum');
      }
    }
  }

  return (
    <div className="MainMenu" data-open={menuOpen}>
      <header className="MainMenu--header">
        <button className="MainMenu--closebutton" onClick={toggleMenu}>Close</button>
      </header>

      <main className={cx('MainMenu--main')}>
        <Layout>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <ul className="MainMenu--menu">
                  {menuData && menuData.filter(v => allowedToSeeKaffeeklatsch ? true : v.url.indexOf('forum') === -1).map((item, index) => (
                    <li
                      key={index}
                      data-menu-item={item.url.substring(1, item.url.length)}
                      className={cx('MainMenu--menu-item', { 'MainMenu--menu-item__is-open': submenuOpen.indexOf(index) !== -1 })}
                    >
                      <Link
                        className={cx('MainMenu--link', { 'MainMenu--link__active': activeItem && item.url === activeItem.url })
                        }
                        to={item.url === '/grundteige' ? '/grundteige/pizza-flammkuchen-und-focaccia' : item.url}
                        onClick={toggleMenu}
                      >
                        <figure className="MainMenu--link--figure">
                          <img alt="" className="MainMenu--link--figure--image" src={item.icon.url} />
                        </figure>
                        <span>{item.title}</span>
                      </Link>
                      {item.menu.length > 0 &&
                      <i
                        className="MainMenu--link--caret"
                        onClick={() => toggleSubmenu(index)}
                      />}

                      {item.menu.length > 0 && (
                        <ul className="MainMenu--submenu">
                          {item.menu.map((subitem, subindex) => (
                            <li key={subindex} className="MainMenu--submenu--menu-item">
                              <Link
                                className={cx('MainMenu--link', { 'MainMenu--link__active': (subitem.url === location?.pathname) || subitem.title === 'Mehr anzeigen...' })
                                }
                                to={subitem.url}
                                onClick={e => {
                                  if (subitem.title === 'Upload') {
                                    if (!userData) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      toggleSignInUpModal();
                                    } else {
                                      toggleMenu();
                                    }
                                  } else {
                                    toggleMenu();
                                  }
                                }}
                              >{subitem.title}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                  {userData && (
                    <li className="MainMenu--menu-item">
                      <Link
                        className="MainMenu--link"
                        to="/mein-profil"
                        onClick={toggleMenu}
                      >
                        <figure className="MainMenu--profile-image">
                          <img className="MainMenu--profile-image--image" src={userData.profileImage} alt="Profilbild" />
                        </figure>
                        <span className="MainMenu--profile-link">
                          Profil
                          {!!notificationCount && (
                            <span className={cx('MainMenu--notif-count')}>
                              {notificationCount <= 99 ? notificationCount : '99+'}
                            </span>
                          )}
                        </span>
                      </Link>
                      <ul className="MainMenu--submenu">
                        <li className="MainMenu--submenu--menu-item">
                          <Link
                            className="MainMenu--link"
                            to="/mein-profil"
                            onClick={toggleMenu}
                          >Mein Profil</Link>
                        </li>
                        <li className="MainMenu--submenu--menu-item">
                          <Link
                            className="MainMenu--link"
                            to="/upload"
                            onClick={toggleMenu}
                          >Hochladen</Link>
                        </li>
                        <li className="MainMenu--submenu--menu-item">
                          <Link
                            className="MainMenu--link"
                            to="/meine-rezepte"
                            onClick={toggleMenu}
                          >Eigene Inhalte</Link>
                        </li>
                        <li className="MainMenu--submenu--menu-item">
                          <Link
                            className="MainMenu--link"
                            to="/meine-favoriten"
                            onClick={toggleMenu}
                          >Favoriten</Link>
                        </li>
                        <li className="MainMenu--submenu--menu-item">
                          <Link
                            className="MainMenu--link"
                            to="/meine-nachrichten"
                            onClick={toggleMenu}
                          >
                            <span>
                              Aktivitäten
                              {!!notificationCount && (
                                <span className={cx('MainMenu--notif-count', {
                                  inDropdown: true,
                                })}>
                                  {notificationCount <= 99 ? notificationCount : '99+'}
                                </span>
                              )}
                            </span>
                          </Link>
                        </li>
                        <li className="MainMenu--submenu--menu-item">
                          <Link
                            className="MainMenu--link"
                            to="/abmelden"
                            onClick={toggleMenu}
                          >Abmelden</Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  {userData && (
                    <li className="MainMenu--menu-item">
                      <Link
                        className="MainMenu--link MainMenu--logout-mobile"
                        to="/abmelden"
                        onClick={toggleMenu}>
                        <figure className="MainMenu--profile-image">
                          <img className="MainMenu--link--figure--image" src={logoutIcon} alt="Abmelden" />
                        </figure>
                        <span className="MainMenu--profile-link">Abmelden</span>
                      </Link>
                    </li>
                  )}
                </ul>
                {location?.pathname !== '/suche' &&
                <Link to="/suche">
                  <button
                    className="MainMenu--search-cta"
                  >
                    Suche
                  </button>
                </Link>}
              </div>
            </div>
          </div>
        </Layout>
      </main>
    </div>
  );
}

export default MainMenu;
