import { useNavigate, useLocation } from 'react-router-dom';
import Page from './presentation';
import React from 'react';

const PageWithRouter = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return <Page {...props} navigate={navigate} location={location} />;
};

export default PageWithRouter;
