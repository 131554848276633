import { useEffect } from 'react';
import { selectGTMConsentState } from '../redux/modules/gtm-data-layer/selectors';
import { updateTracker } from '../tools/Tracker';
import { useSelector } from './useSelector';

export function useInitTracker() {
  const gtmConsentState = useSelector(selectGTMConsentState);

  useEffect(() => {
    updateTracker(gtmConsentState);
  }, [gtmConsentState]);
}
