import React, { Fragment, Component } from 'react';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import API from '../../tools/API';

import RatingInput from './RatingInput';
import SingleUserComment from '../ReplyBoxes/SingleUserComment';
import RecipeTeaser from '../RecipeTeaser';

import Modal from '../../elements/Modal';
import ModalCloseButton from '../../elements/Modal/CloseButton';
import Layout from '../../elements/Layout/Container';
import Headline from '../../elements/Headline';
import Textarea from '../../elements/Form/Textarea';
import Button from '../../elements/Button/Distorted';

import './Styles.css';
// import LoadingIndicator from '../../elements/LoadingIndicator';

const MOBILE_BREAKPOINT = 740;

class CommentOverlay extends Component {
  state = {
    errors: {},
    comments: [],
    rating: null,
    replyModalOpen: null,
    replyText: '',
    loading: false,
    imageToBeUploaded: null,
    previewImageInUI: null,
    previewImageInUIWidth: 200,
    previewImageInUIHeight: 200,
    currentRecipeId: null,
    viewportWidth: window.innerWidth,
    hasBeenRatedAlreadyByYou: false,

    newCommentText: '',
    imagesInRepliesInfo: [],
    showSubmitSuccessMessage: false,
  }

  constructor(props) {
    super(props);
    this._onResize = debounce(this._onResize, 250);
  }

  deleteComment = (id) => {
    this.setState({ loading: true });
    API.cmDeleteComment(id).then(() => this.fetchComments(this.props.recipe.id));
  }

  sendComment = () => {
    const {
      recipe: {
        id,
        ID,
        post_name: postName,
        slug,
      },
      authUser,
      userData,
      setCommentRecipe,
    } = this.props;
    const { rating, loading, imageToBeUploaded, newCommentText } = this.state;

    const savedRating = userData && userData.ratings && userData.ratings.find(v => v[0] === id);

    if (!loading) {
      this.setState({ loading: true });
    }

    if (rating && !savedRating) {
      API.setRating(id || ID, rating)
        .then(() => {
          API.getUserData().then(newAuth => {
            if (newAuth.success) {
              authUser(newAuth.userdata);
              this.setState({
                loading: false,
              });
              API.fetchRecipeBySlug(slug, false).then(res => {
                const recipe = res[0];
                if (!recipe) {
                  return;
                }
                if (!recipe.recipeData) {
                  return;
                }
                this.setState({
                  rating: parseInt(recipe.recipeData.rating, 10),
                  hasBeenRatedAlreadyByYou: newAuth.userdata.ratings.some(rating => rating[0] === recipe.id),
                });
              });
              if (this.props.callbackOnVoteOrComment) {
                this.props.callbackOnVoteOrComment();
              }
            }
          });
        });
    }
    if (newCommentText) {
      API.sendComment(id || ID, newCommentText, savedRating ? null : rating, imageToBeUploaded)
        .then((_response) => {
          if (_response.success) {
            this.setState({
              previewImageInUIWidth: 200,
              previewImageInUIHeight: 200,
              previewImageInUI: null,
              imageToBeUploaded: null,
              newCommentText: '',
              showSubmitSuccessMessage: true,
            });

            this.fetchComments(id || ID);
            API.fetchRecipeBySlug(postName || slug, false).then(res => {
              if (res[0]) {
                setCommentRecipe(res[0]);
              }
            });
            if (this.props.callbackOnVoteOrComment) {
              this.props.callbackOnVoteOrComment();
            }
          }
        });
    }
  }

  sendReply = imageID => {
    const { recipe: { id, ID, post_name: postName, slug } } = this.props;
    const { replyText, replyModalOpen, loading, imagesInRepliesInfo } = this.state;
    if (!replyText) {
      return;
    }
    const image = imagesInRepliesInfo.find(a => a.id === imageID);

    if (!loading) {
      this.setState({ loading: true });
    }

    API.sendComment(id, replyText, undefined, image && image.image, replyModalOpen)
      .then((_response) => {
        if (_response.success) {
          this.setState({
            replyModalOpen: null,
            replyText: '',
            imagesInRepliesInfo: [],
            rating: 5,
          });
        }
      })
      .then(() => this.fetchComments(id || ID, postName || slug));
  }

  setRating = (rating) => {
    this.setState({
      rating,
    });
  }

  fetchComments = (id, postName) => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    return Promise.all([API.fetchCommentsByPostId(id), API.getRatingsForRecipe(id)]).then(_responses => {
      const { userData } = this.props;
      if (_responses[1].success === true && userData && userData.id) {
        const { user_ratings: userRatings = [] } = _responses[1];
        this.setState({ hasBeenRatedAlreadyByYou: userRatings.some(rating => rating[0] === userData.id) });
      }
      if (_responses[0].length > 0 && _responses[1].success === true) {
        const comments = _responses[0].map(v => {
          let rating = _responses[1].user_ratings.find(r => r[0] === v.author && v.parent === 0);
          if (rating) {
            rating = rating[1];
          }
          return ({
            ...v,
            rating,
          });
        });
        this.setState({ comments, loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  showSignupModal = () => {
    this._onCloseAction();
    this.props.toggleSignInUpModal();
  }

  componentDidMount() {
    window.addEventListener('resize', this._onResize);
  }

  componentDidUpdate(prevProps) {
    const {
      recipe,
      isOpen,
      setCommentRecipe,
    } = this.props;
    if (!recipe) {
      return;
    }
    if (this.props.activeRecipeID !== prevProps.activeRecipeID) {
      if (this.props.activeRecipeID === recipe.id) {
        if (isOpen) {
          this._onCloseAction();
          this.setState({
            comments: [],
            rating: null,
          });
        }
      }
    }
    // if (prevProps.recipe) {
    //   if (prevProps.recipe.id === recipe.id) {
    //     return
    //   } else {
    //     if (isOpen) {
    //       this.fetchComments()
    //     }
    //   }
    // }
    if (isOpen !== prevProps.isOpen) {
      if (isOpen) {
        const { id, ID, post_name: postName, slug } = recipe;
        this.fetchComments(id || ID);
        API.fetchRecipeBySlug(postName || slug, false).then(res => {
          setCommentRecipe(res[0]);
        });
      } else {
        this.setState({
          comments: [],
          rating: null,
          replyText: '',
          newCommentText: '',
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize);
  }

  _onResize = () => {
    this.setState({ viewportWidth: window.innerWidth });
  }

  _getCommentDepth = (comments, id, curDepth = 0) => {
    const comment = comments.find(c => c.id === id);
    if (comment.parent === 0) {
      return curDepth;
    }
    return this._getCommentDepth(comments, comment.parent, curDepth + 1);
  }

  _collectChildComments = (comments, id, children = []) => {
    comments.forEach(c => {
      if (c.parent === id) {
        children.push(c);
        children.concat(this._collectChildComments(comments, c.id, children));
      }
    });
    return children;
  }

  _transformToNestedComments = (comments) => {
    const rootLevelComments = comments.filter(v => v.parent === 0);
    return rootLevelComments
      .reduce((a, c) => a.concat(c, this._collectChildComments(comments, c.id)), [])
      .map(c => ({ ...c, depth: this._getCommentDepth(comments, c.id) }));
  }

  _showReplyModal = (id) => {
    this.setState({ replyModalOpen: id, replyText: '' });
  }

  _setReply = (e) => {
    this.setState({ replyText: e.target.value });
  }

  _handleAttachedCommentPicture = imageToBeUploaded => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const previewImageInUI = fileReader.result;
      this.setState({ previewImageInUI });
      const img = document.createElement('img');
      img.onload = () => {
        const { width, height } = img;
        const MAX_WIDTH = 290;
        const scaleFactorToDownscalePreviewImg = MAX_WIDTH / width;
        const previewImageInUIWidth = width * scaleFactorToDownscalePreviewImg;
        const previewImageInUIHeight = height * scaleFactorToDownscalePreviewImg;
        this.setState({ previewImageInUIWidth, previewImageInUIHeight });
        document.body.appendChild(img);
      };
      img.src = previewImageInUI;
    };
    fileReader.readAsDataURL(imageToBeUploaded);
    this.setState({ imageToBeUploaded });
  }

  _handleAttachedReplyPicture = (imageToBeUploaded) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const previewImageInUI = fileReader.result;
      const imageData = {
        image: imageToBeUploaded,
        id: imageToBeUploaded.name || imageToBeUploaded.preview,
        previewImageInUI,
      };
      const imagesInRepliesInfo = [...this.state.imagesInRepliesInfo, imageData];
      this.setState({ imagesInRepliesInfo });
    };
    fileReader.readAsDataURL(imageToBeUploaded);
  }

  _onCloseAction = () => {
    const { onClose } = this.props;
    onClose();
    this.setState({
      comments: [],
      newCommentText: '',
      rating: null,
      showSubmitSuccessMessage: false,
    });
  }

  render() {
    const {
      userData,
      isOpen,
      recipe,
      signinupModalOpen,
      activeRecipeID,
      toggleSignInUpModal,
    } = this.props;

    const {
      errors,
      comments,
      rating,
      replyModalOpen,
      loading,
      replyText,
      newCommentText,
      viewportWidth,
      showSubmitSuccessMessage,
      hasBeenRatedAlreadyByYou,

      imagesInRepliesInfo,
    } = this.state;

    if (!recipe) {
      return null;
    }

    const {
      author,
      id,
    } = recipe;

    if (signinupModalOpen) {
      return null;
    }

    const transformedComments = this._transformToNestedComments(comments);

    const ownRecipe = (userData && userData.id) === author;
    const allMandatoryFieldsFilled = !!rating || !!newCommentText;
    const savedRating = userData && userData.ratings && userData.ratings.find(v => v[0] === id);
    const compoundRating = rating || (savedRating && savedRating[1]);

    const isSubmitButtonDisabled = !userData || !allMandatoryFieldsFilled;

    const isMobile = viewportWidth < MOBILE_BREAKPOINT;

    // let body = null

    // if (loading) {
    //   body = (
    //     <div className="row">
    //       <div className="col-lg-8 col-lg-offset-2">
    //         <LoadingIndicator />
    //       </div>
    //     </div>
    //   )
    // } else if (curTabIdx === RATING_TAB) {
    //   body = (
    //     <div className="row">
    //       <div className="col-lg-8 col-lg-offset-2">
    //         <header className="CommentOverlay--header">
    //           <Headline>Bewertung abgeben</Headline>
    //           <RatingInput onSelect={this.setRating} defaultRating={compoundRating} />
    //         </header>

    //         <main className="CommentOverlay--body">
    //           <Textarea
    //             data-error={errors.textarea}
    //             onChange={({ target: { value: newCommentText } }) => this.setState({ newCommentText })}
    //             data-dark="true"
    //             data-no-shadow="true"
    //             placeholder="Kommentar abgeben"
    //           />
    //           <br/><br/>
    //           <Headline>Bild hinzufügen</Headline>
    //           <br/>
    //           <DropzoneWrapper
    //             onDrop={this._handleAttachedCommentPicture} />
    //         </main>

    //         <footer className="CommentOverlay--footer">
    //           {ownRecipe && (
    //             <p className="CommentOverlay--footer-text">Du kannst deine eigenen Rezepte nicht bewerten.</p>
    //           )}
    //           {savedRating && (
    //             <p className="CommentOverlay--footer-text">Du hast dieses Rezept schon bewertet.</p>
    //           )}
    //           {!userData && (
    //             <p className="CommentOverlay--footer-text"><a onClick={this.showSignupModal} className="CommentOverlay--link">Logge dich erst ein</a>, um das Rezept zu bewerten.</p>
    //           )}
    //           <Button
    //             onClick={this.sendComment}
    //             data-inline="true"
    //             disabled={isSubmitButtonDisabled || (rating && savedRating)}>{ rating && newCommentText ? 'Bewerten und Kommentieren' : (rating ? 'Bewerten' : 'Kommentieren') }</Button>
    //         </footer>
    //       </div>
    //     </div>
    //   )
    // } else if (curTabIdx === COMMENT_TAB) {
    //   if (transformedComments.length === 0) {
    //     body = (
    //       <div className="row">
    //         <main className="CommentOverlay--body">
    //           <div className="col-lg-8 col-lg-offset-2">
    //             <p>Es gibt noch keine Kommentare zu diesem Rezept.</p>
    //           </div>
    //         </main>
    //       </div>
    //     )
    //   } else {
    //     body = (
    //       <div className="row">
    //         <main className="CommentOverlay--body">
    //           <div className="col-lg-8 col-lg-offset-2">
    //             <ul>
    //               {transformedComments.map(v => (
    //                 <SingleUserComment
    //                   replyModalOpen={replyModalOpen}
    //                   replyText={replyText}
    //                   imagesInRepliesInfo={imagesInRepliesInfo}
    //                   key={v.id}
    //                   {...v}
    //                   showReplyModal={this._showReplyModal}
    //                   handleAttachedReplyPicture={this._handleAttachedReplyPicture}
    //                   setReply={this._setReply}
    //                   sendReply={this.sendReply}
    //                   deleteComment={this.deleteComment} />
    //               ))}
    //             </ul>
    //           </div>
    //         </main>
    //       </div>
    //     )
    //   }
    // }

    return (
      <Modal
        isOpen={isOpen}
        className="CommentOverlay--Modal"
        onRequestClose={this._onCloseAction}
      >
        <ModalCloseButton onClick={this._onCloseAction}/>
        <div className="CommentOverlay">
          <div className="CommentOverlay--inner">
            <Layout>
              <div className="CommentOverlay--inner-wrapper">
                <div className={cx('CommentOverlay--teaser-container', {
                  'CommentOverlay--teaser-container_isMobile': isMobile,
                })}>
                  {
                    isMobile
                      ? (
                        <div
                          className="CommentOverlay--mobile-teasers-wrapper"
                        >
                          <RecipeTeaser
                            className="CommentOverlay--inline-teaser CommentOverlay--inline-mobile-teaser"
                            pictureHeightRatio={0.75}
                            recipe={recipe}
                            hoverable={false}
                            onAuthorClick={this._onCloseAction}
                            showMeta={false}
                            linkable={id !== activeRecipeID}
                          />
                          <RecipeTeaser
                            className="CommentOverlay--inline-teaser CommentOverlay--inline-mobile-teaser"
                            pictureHeightRatio={0.75}
                            recipe={recipe}
                            hoverable={false}
                            onAuthorClick={this._onCloseAction}
                            showPicture={false}
                            linkable={id !== activeRecipeID}
                          />
                        </div>
                        )
                      : (
                        <RecipeTeaser
                          className="CommentOverlay--inline-teaser"
                          pictureHeightRatio={0.75}
                          recipe={recipe}
                          hoverable={false}
                          onAuthorClick={this._onCloseAction}
                          linkable={id !== activeRecipeID}
                        />
                        )
                  }
                </div>
                <div className={cx('CommentOverlay--rating-container', {
                  'CommentOverlay--loading': loading,
                })}>
                  <div className="CommentOverlay--rating-wrapper">
                    {!showSubmitSuccessMessage && !ownRecipe && (
                      <Fragment>
                        <Headline>{hasBeenRatedAlreadyByYou ? 'Deine Bewertung' : 'Bewertung abgeben'}</Headline>
                        <RatingInput
                          onSelect={this.setRating}
                          defaultRating={compoundRating}
                          disabled={savedRating}
                        />
                      </Fragment>
                    )}
                  </div>
                  <div className="CommentOverlay--comment-input">
                    {!userData && (
                      <div
                        className="CommentOverlay--comment-input-not-logged-in"
                      >
                        Bitte <button
                          onClick={() => {
                            toggleSignInUpModal();
                          }}
                        >logge</button> dich ein, damit du kommentieren kannst.
                      </div>
                    )}
                    {
                      showSubmitSuccessMessage
                        ? (
                          <Headline
                            className="CommentOverlay--success-comment"
                          >
                            Danke für deine bewertung!
                          </Headline>
                          )
                        : (
                          <div>
                            <Textarea
                              disabled={!userData}
                              data-error={errors.textarea}
                              value={newCommentText}
                              onChange={({ target: { value: newCommentText } }) => this.setState({ newCommentText })}
                              data-dark="true"
                              data-no-shadow="true"
                              placeholder={userData && 'Kommentar abgeben'}
                            />
                            <div className="CommentOverlay--send-comment-btn">
                              <Button
                                onClick={this.sendComment}
                                data-inline="true"
                                disabled={isSubmitButtonDisabled}
                              >
                                Senden
                                {/* {rating && newCommentText ? 'Bewerten und Kommentieren' : (rating ? 'Bewerten' : 'Kommentieren') } */}
                              </Button>
                            </div>
                          </div>
                          )
                    }
                  </div>
                  <div
                    className={cx('CommentOverlay--comments-wrapper', {
                      'CommentOverlay--no-comments': !transformedComments || !transformedComments.length,
                    })}>
                    <ul>
                      {transformedComments && transformedComments.length
                        ? transformedComments.map(v => (
                          <SingleUserComment
                            replyModalOpen={replyModalOpen}
                            replyText={replyText}
                            imagesInRepliesInfo={imagesInRepliesInfo}
                            key={v.id}
                            {...v}
                            showReplyModal={this._showReplyModal}
                            handleAttachedReplyPicture={this._handleAttachedReplyPicture}
                            setReply={this._setReply}
                            sendReply={this.sendReply}
                            deleteComment={this.deleteComment} />
                        ))
                        : 'Es gibt noch keine Kommentare zu diesem Rezept.'
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </Layout>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CommentOverlay;
