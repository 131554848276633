import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import throttle from 'lodash.throttle';
// import sampleSize from 'lodash.samplesize';
import { Helmet } from 'react-helmet';
// import { scroller } from 'react-scroll';

import './Styles.css';
import Page from '../Page';

import heroDesktop from '../../images/activation-campaign/AURO_Header_Desktop_2202x908.webp';
import heroMobile from '../../images/activation-campaign/AURO_Header_Mobile_640x896.webp';
import andrea from '../../images/activation-campaign/IMG-Andrea@2x.webp';
import kitchen from '../../images/activation-campaign/IMG-KitchenAid@2x.webp';
import backkiste from '../../images/activation-campaign/IMG-Backkiste@2x-new.webp';
import brand1 from '../../images/activation-campaign/brands/Aurora-Logo-Tortenspitze.png';
import brand2 from '../../images/activation-campaign/brands/Diamant-Logo-Tortenspitze.png';
import brand3 from '../../images/activation-campaign/brands/Goldpuder-Logo-Tortenspitze.png';
import brand4 from '../../images/activation-campaign/brands/Rosenmehl-Logo-Tortenspitze.png';
import kaiser from '../../images/activation-campaign/brands/Kaiser-Logo.svg';
import CustomSelect from '../../components/CustomSelect';
import RecipeSlider from '../../components/RecipeSlider';

export default class ActivationCampaignResults extends Component {
  state = {
    viewportWidth: window.innerWidth,
    won: false,
    backkiste: false,
    event: false,
    success: false,
    selectedEvent: '',
    eventDate: '',
    firstname: '',
    lastname: '',
    street: '',
    plz: '',
    city: '',
    errors: {},
  }

  _onResize = throttle(() => {
    this.setState({ viewportWidth: window.innerWidth });
  }, 200)

  validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!this.state.firstname) {
      formIsValid = false;
      errors.firstname = 'Vorname ist erforderlich';
    }

    if (!this.state.lastname) {
      formIsValid = false;
      errors.lastname = 'Nachname ist erforderlich';
    }

    if (!this.state.street) {
      formIsValid = false;
      errors.street = 'Straßenname ist erforderlich';
    }

    if (!this.state.plz) {
      formIsValid = false;
      errors.plz = 'Postleitzahl ist erforderlich';
    }

    if (!this.state.city) {
      formIsValid = false;
      errors.city = 'Stadtname ist erforderlich';
    }

    this.setState({ errors });
    return formIsValid;
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this._onResize);
    // this.handleFacebookFeed();
    this.scrollToHashId();
    const participationData = localStorage.getItem('participationData');
    if (participationData) {
      const parsedData = JSON.parse(participationData);
      if (parsedData.prize === 'paket') {
        this.setState({ backkiste: true });
        this.setState({ won: true });
      } else if (parsedData.prize === 'backevent') {
        this.setState({ event: true });
        this.setState({ won: true });
      } else {
        this.setState({ won: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onResize);
  }

  handleEventChange = (selectedValue) => {
    const dateMatch = selectedValue.match(/(\d{2})\.(\d{2})\.(\d{4})/);
    const selectedDate = dateMatch ? `${dateMatch[3]}-${dateMatch[2]}-${dateMatch[1]}` : null;

    this.setState({
      selectedEvent: selectedValue,
      eventDate: selectedDate,
    });
  }

  _sendEventDate = async (event) => {
    event.preventDefault();

    // Assuming you've collected all the data in your state:
    const storedData = JSON.parse(localStorage.getItem('participationData'));
    const payload = {
      participationId: storedData.participationId,
      EventDate: this.state.eventDate,
    };

    try {
      const response = await fetch('https://www.promoapi.de/llb-api/application/rest/add-event-winner', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': 'LIVE_UQ4CJ91ZHCW16I4U51D0UIYBCEYQEJKG',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      // Handle the response accordingly
      if (response.ok) {
        this.setState({ success: true });
      } else {
        console.error('Submission Failed: ', result);
      }
    } catch (error) {
      console.error('There was an error submitting the form: ', error);
    }
  }

  _onSubmit = async (event) => {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    // Assuming you've collected all the data in your state:

    const payload = {
      FirstName: this.state.firstname,
      LastName: this.state.lastname,
      Address: this.state.street,
      PostalCode: this.state.plz,
      City: this.state.city,
      participationId: JSON.parse(localStorage.getItem('participationData')).participationId,
    };

    try {
      const response = await fetch('https://www.promoapi.de/llb-api/application/rest/add-backingset-winner', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': 'LIVE_UQ4CJ91ZHCW16I4U51D0UIYBCEYQEJKG',
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      // Handle the response accordingly
      if (response.ok) {
        this.setState({ success: true });
        this.scrollToSuccess();
      } else {
        console.error('Submission Failed: ', result);
      }
    } catch (error) {
      console.error('There was an error submitting the form: ', error);
    }
  }

  // emulate URL anchor page scroll functionality
  scrollToHashId() {
    const removeHash = this.removeHash;
    // get URL hash (minus the hash mark)
    const hash = window.location.hash.substring(1);

    // if there's a hash, scroll to that ID
    if (hash && hash.length) {
      // setTimeout and requestAnimationFrame help ensure a true DOM repaint/reflow before we try to scroll
      // - reference: http://stackoverflow.com/a/34999925
      setTimeout(
        window.requestAnimationFrame(() => {
          const el = document.getElementById(hash);
          el.scrollIntoView();
          // clean up the hash, so we don't scroll on every prop update
          removeHash();
        }),
        0,
      );
    }
  }

  scrollToSuccess() {
    const successElement = document.getElementById('success');
    if (successElement) {
      successElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // borrowed from http://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-with-javascript-without-page-refresh/5298684#5298684
  removeHash() {
    const loc = window.location;
    const hist = window.history;

    // use modern browser history API
    if (hist && 'pushState' in hist) {
      hist.replaceState('', document.title, loc.pathname + loc.search);
    // fallback for older browsers
    } else {
      // prevent scrolling by storing the page's current scroll offset
      const scrollV = document.body.scrollTop;
      const scrollH = document.body.scrollLeft;

      loc.hash = '';

      // restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  }

  render() {
    return (
      <Page className="ActivationCampaignResults">
        <Helmet>
          <title>Lebeliebebacke Ergebnis</title>
        </Helmet>
        <section className="ActivationCampaignResults__hero">
          <img src={this.state.viewportWidth < 1080 ? heroMobile : heroDesktop} alt="" />
        </section>

        <section className="ActivationCampaignResults__description" id="ergebnis">
          {this.state.won
            ? <h1>Herzlichen Glückwunsch: <br />
              Du hast {this.state.backkiste ? 'eine Backkiste' : 'ein Backevent'} gewonnen.</h1>
            : <h1>Leider hast du diesmal nicht gewonnen!</h1>}
          {
            this.state.won
              ? <p>
          Bevor wir Dich untenstehend mit weiteren Informationen versorgen, erhältst Du hier bereits deinen Rabattcode für den WMF Onlineshop – exklusiv für Produkte der Marke Kaiser (Mindestbestellwert: 49 €):
              </p>
              : <p>Wie versprochen erhältst du hiermit aber deinen Rabattcode für den WMF-Onlineshop – exklusiv für Produkte der Marke Kaiser (Mindestbestellwert: 49 €):</p>
          }
          <img src={kaiser} alt="" />
          <h2>
          Y4R2M3EM
          </h2>
          {
            this.state.won
              ? <p>Nach erfolgreicher Prüfung deines Kassenbons bekommst du von uns eine Bestätigungs-E-Mail mit allen Infos zur weiteren Gewinnabwicklung.</p>
              : <p>Vielleicht klappt es ja das nächste Mal. <br /><br /> Unser Tipp: Probiere es erneut! Einfach weiteres Aktionsprodukt kaufen, Kassenbon hochladen und Daumen drücken. Auch ein anspruchsvolles Kuchenrezept braucht manchmal mehrere Versuche ...
                <br /><br />Liebe Grüße & bis zum nächsten Mal!</p>
          }
        </section>

        {
          (this.state.event && !this.state.success) &&
        <section className="ActivationCampaignResults__price">
          <h2>Die Backevents finden im April 2024 statt. <br />
Wähle deinen Wunschtermin aus:</h2>
          <div className="events">
            <CustomSelect onValueChange={this.handleEventChange} options={['Samstag 12.04.2024 nachmittags', 'Sonntag 13.04.2024 vormittags', 'Samstag 20.04.2024 vormittags', 'Samstag 20.04.2024 nachmittags', 'Sonntag 21.04.2024 vormittags']} placeholder="Ihr Wunschtermin*" />
          </div>
          <p>*Pflichtfelder</p>
          <div className="events-button">
            <Link
              className="button"
              to={'#'}
              onClick={this._sendEventDate}
            >
              <i></i>Absenden
            </Link>
          </div>
          <p>
          Freue dich schon jetzt auf ein unvergessliches Backevent mit deinen fünf Liebsten und der Konditoren-Weltmeisterin Andrea Schirmaier-Huber. <br /><br /> Das wird ein Genuss!<br /><br />
Liebe Grüße & guten Appetit!
          </p>
          <div className="images">
            <img className="left" src={andrea} alt="" />
            <img src={kitchen} alt="" />
            <p><strong>Andrea Schirmaier-Huber</strong></p>
            <p>PS: Wir freuen uns, wenn du Fotos von eurem Backevent und euren Kuchen-Kreationen auf unserer Facebook-Seite teilst.</p>
          </div>
        </section>
        }

        {
          (this.state.won && this.state.backkiste && !this.state.success) &&
        <section className="ActivationCampaignResults__price">
          <h2>Freue dich schon jetzt auf deine Backkiste!</h2>

          <div className="item">
            <div className="content">
              <img className="" src={backkiste} alt="Backkisten" />
            </div>
            <div className="content">
              <p>Denn diese enthält alles, was das Herz eines echten Backfans höher schlagen lässt</p>
              <ul>
                <li>inklusive Rezept</li>
                <li>inklusive Zutaten</li>
                <li>exklusiv mit Kaiser Herz-Backform</li>
              </ul>
            </div>
          </div>

          <h2 className="marmor-h2">Bitte gib deine Adresse ein, damit wir dir deinen Gewinn
per Post zustellen können:</h2>
          <form className="form" onSubmit={this._onSubmit}>

            <div className="form-group">
              <input type="text" className="form-control" id="firstname" placeholder="Vorname*" onChange={this.handleInputChange} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" id="lastname" placeholder="Nachname*" onChange={this.handleInputChange} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" id="street" placeholder="Straße & Hausnummer*" onChange={this.handleInputChange} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" id="plz" placeholder="PLZ*" onChange={this.handleInputChange} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" id="city" placeholder="Stadt*" onChange={this.handleInputChange} />
            </div>

            <p className="mendatory">*Pflichtfelder</p>
            <div className="form-errors">
              {Object.keys(this.state.errors).map((key, index) => (
                <span key={index} className="error">{this.state.errors[key]}</span>
              ))}
            </div>
            <div className="submit-button">
              <button type="submit" className="button mt-4 block">
                <i></i>Absenden
              </button>
            </div>

            <p className="contact">
Bei Fragen wende Dich gerne an unseren Customer Service <br />
              <a href="mailto:lebeliebebacke@promotionservice.de" target="_blank" rel="noreferrer">lebeliebebacke@promotionservice.de</a>
            </p>
            <p>PS: Wir freuen uns, wenn du Fotos von eurem Backevent und euren Kuchen-Kreationen auf unserer Facebook-Seite teilst.</p>

          </form>
        </section>
        }

        {
          this.state.success &&
          <section className="ActivationCampaignResults__price" id="success">
            <h2>Prima, das hat funktioniert!</h2>

            <p>
          Bei Fragen wende Dich gerne an unseren Customer Service <br />
              <a href="mailto:lebeliebebacke@promotionservice.de" target="_blank" rel="noreferrer">lebeliebebacke@promotionservice.de</a>
            </p>
            <p>
          PS: Wir freuen uns, wenn du Fotos von eurem Backevent und euren Kuchen-Kreationen auf unserer Facebook-Seite teilst.
            </p>
          </section>
        }

        <section className="ActivationCampaignResults__brands">
          <h2>Teilnehmende Marken</h2>
          <p className="subtitle">Alle guten Dinge sind vier!</p>

          <div className="brands">
            <a id="aurora" className="brand" href="https://www.aurora-mehl.de/" target="_blank" rel="noreferrer"><img src={brand1} alt="" /></a>
            <a id="diamant" className="brand" href="https://www.diamant-mehl.de/" target="_blank" rel="noreferrer"><img src={brand2} alt="" /></a>
            <a id="goldpuder" className="brand" href="https://www.goldpuder.de/" target="_blank" rel="noreferrer"><img src={brand3} alt="" /></a>
            <a id="rosenmehl" className="brand" href="https://www.rosenmehl.de/" target="_blank" rel="noreferrer"><img src={brand4} alt="" /></a>
          </div>

        </section>
        <section className="ActivationCampaignResults__recipes">
          <RecipeSlider
            title={'Lasst euch inspirieren!'}
            description={'Ob für besondere Gelegenheiten pder einfach so zum Verwöhnen, hier findet ihr leckere und gelingsichere Rezepte.'}
            searchHint={'NOCH NICHT FÜNDIG GEWORDEN? FINDE WEITERE INSPIRATIONEN IN UNSERER SUCHE!'}
            moreButtonText={'Hier geht\'s zu den Rezepten'}
            moreButtonLink={'/rezepte'}
          />
        </section>
      </Page>
    );
  }
}
