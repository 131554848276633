import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from '../../hooks/withRouter';
import CommentOverlay from './presentation';
import { authUser } from '../../redux/modules/content';

import {
  toggleSignInUpModal,
} from '../../redux/modules/ui';

import {
  setCommentOverlayOpen,
  setCommentRecipe,
} from '../../redux/modules/comment-overlay';

export default compose(
  withRouter,
  connect(({
    content: {
      userData,
    },
    ui: {
      signinupModalOpen,
      activeRecipeID,
    },
    commentOverlay: {
      commentOverlayOpen,
      recipe,
      callbackOnVoteOrComment,
    },
  }) => ({
    isOpen: commentOverlayOpen,
    activeRecipeID,
    recipe,
    callbackOnVoteOrComment,
    userData,
    signinupModalOpen,
  }), {
    toggleSignInUpModal,
    authUser,
    onClose: () => setCommentOverlayOpen(false),
    setCommentRecipe,
  }),
)(CommentOverlay);
