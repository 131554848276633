import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import rootReducer from '../modules';

export const history = createBrowserHistory();

const store = configureStore({
  reducer: rootReducer,
});

export default store;
