import { compose } from 'redux';
import withRouter from '../hooks/withRouter';
import { connect } from 'react-redux';

import {
  setCachedTeasers,
  setPreviousPagePathname,
  setCurrentPagePathname,
} from '../redux/modules/content';

import {
  setViewportWidth,
} from '../redux/modules/ui';

import AppView from './presentation';

export default compose(
  withRouter,
  connect(() => ({}), {
    setCachedTeasers,
    setPreviousPagePathname,
    setCurrentPagePathname,
    setViewportWidth,
  }),
)(AppView);
