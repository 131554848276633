import { useContext, useEffect, useMemo, useState } from 'react';
import { ReduxStoreContext } from '../redux/ReduxProvider';

export function useSelector(selector) {
  const context = useContext(ReduxStoreContext);
  const [selectedState, setSelectedState] = useState(selector(context && context.store && context.store.getState()));

  useEffect(() => {
    const store = context && context.store;
    if (!store) return;
    const unsubscribe = store.subscribe(() => {
      const state = store && store.getState();
      if (!state) return;
      setSelectedState(selector(state));
    });
    return unsubscribe;
  }, [context.store]);

  return useMemo(() => selectedState, [selectedState]);
}
