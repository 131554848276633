import Cookies from 'universal-cookie';
import store from '../../redux/store';
import { authUser } from '../../redux/modules/content';

import API from '../API';

import {
  getLocalStorageItemSafe,
  setLocalStorageItemSafe,
} from '../helper';

const cookies = new Cookies();
const cookiePrefix = 'backmomente_';

const Bootstrap = {
  getHasLoggedIn() {
    return !!getLocalStorageItemSafe('bootstrap-has-logged-in');
  },

  setHasLoggedIn() {
    setLocalStorageItemSafe('bootstrap-has-logged-in', true);
  },

  run() {
    Bootstrap.authByToken();
  },

  setAuthToken(token) {
    const cookieName = `${cookiePrefix}_authToken`;
    cookies.set(cookieName, token, {
      path: '/',
    });
  },

  authByToken() {
    const cookieName = `${cookiePrefix}_authToken`;
    const token = cookies.get(cookieName);
    if (token) API.authByToken(token);
  },

  logout() {
    const cookieName = `${cookiePrefix}_authToken`;
    cookies.remove(cookieName);
    store.dispatch(authUser(null));
  },
};

export default Bootstrap;
