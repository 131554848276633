import React, { Component } from 'react';
import cx from 'classnames';
import { Route, Routes, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import PageLoadable from '../pages/Page/Loadable';
import { getScrollY } from '../tools/helper';
import debounce from 'lodash.debounce';

import SignInUpOverlay from '../components/SignInUpOverlay';
import RequestNewPasswordOverlay from '../components/RequestNewPasswordOverlay';
import CommentOverlay from '../components/CommentOverlay';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { GRUNDTEIGE_BASE_ROUTE, LEBELIEBEBACKE_BASE_ROUTE } from './routeNames';
import AppGlobalHookLoader from './AppGlobalHookLoader';
import LebeLiebeBacke from '../pages/LebeLiebeBackePage';
import ActivationCampaign from '../pages/ActivationCampaign';
import ActivationCampaignResults from '../pages/ActivationCampaignResults';
import ActivationCampaignEnds from '../pages/ActivationCampaignEnds';

const HomePage = Loadable({
  loader: () => import('../pages/HomePage/'),
  loading: PageLoadable,
});

const RecipePage = Loadable({
  loader: () => import('../pages/RecipePage/'),
  loading: PageLoadable,
});

const RecipePageSebstgebacken = Loadable({
  loader: () => import('../pages/RecipePageSebstgebacken/'),
  loading: PageLoadable,
});

const Analytics = Loadable({
  loader: () => import('../pages/Analytics/'),
  loading: PageLoadable,
});

const OverviewPage = Loadable({
  loader: () => import('../pages/OverviewPage/'),
  loading: PageLoadable,
});

const ChannelOverviewPage = Loadable({
  loader: () => import('../pages/ChannelOverviewPage'),
  loading: PageLoadable,
});

const ChannelPage = Loadable({
  loader: () => import('../pages/ChannelPage'),
  loading: PageLoadable,
});

const ForumThreadPage = Loadable({
  loader: () => import('../pages/ForumThreadPage'),
  loading: PageLoadable,
});

const ArticlePage = Loadable({
  loader: () => import('../pages/ArticlePage/'),
  loading: PageLoadable,
});

const ContentPage = Loadable({
  loader: () => import('../pages/ContentPage/'),
  loading: PageLoadable,
});

const UserPage = Loadable({
  loader: () => import('../pages/UserPage/'),
  loading: PageLoadable,
});

const NotFoundPage = Loadable({
  loader: () => import('../pages/NotFoundPage/'),
  loading: PageLoadable,
});

const NewsletterPage = Loadable({
  loader: () => import('../pages/NewsletterPage/'),
  loading: PageLoadable,
});

const NewsletterSuccessPage = Loadable({
  loader: () => import('../pages/NewsletterSuccessPage/'),
  loading: PageLoadable,
});

const RecipesPage = Loadable({
  loader: () => import('../pages/RecipesPage/'),
  loading: PageLoadable,
});

const MyProfilePage = Loadable({
  loader: () => import('../pages/MyProfilePage/'),
  loading: PageLoadable,
});

const MyRecipesPage = Loadable({
  loader: () => import('../pages/MyRecipesPage/'),
  loading: PageLoadable,
});

const MyFavsPage = Loadable({
  loader: () => import('../pages/MyFavsPage/'),
  loading: PageLoadable,
});

const MyNotificationsPage = Loadable({
  loader: () => import('../pages/MyNotificationsPage/'),
  loading: PageLoadable,
});

const CmNotificationsPage = Loadable({
  loader: () => import('../pages/CmNotificationsPage/'),
  loading: PageLoadable,
});

const UploadPage = Loadable({
  loader: () => import('../pages/UploadPage/'),
  loading: PageLoadable,
});

const SearchPage = Loadable({
  loader: () => import('../pages/SearchPage/'),
  loading: PageLoadable,
});

const SignOffPage = Loadable({
  loader: () => import('../pages/SignOffPage/'),
  loading: PageLoadable,
});

const VerifyPage = Loadable({
  loader: () => import('../pages/VerifyPage/'),
  loading: PageLoadable,
});

const SuesseGruessePage = Loadable({
  loader: () => import('../pages/SuesseGruessePage'),
  loading: PageLoadable,
});

const SelbstGebacken = Loadable({
  loader: () => import('../pages/SelbstGebacken'),
  loading: PageLoadable,
});

// const MemoryGamePage = Loadable({
//   loader: () => import('../pages/MemoryGamePage'),
//   loading: PageLoadable
// })

const MemoryGameWinnersPage = Loadable({
  loader: () => import('../pages/MemoryGameWinnersPage'),
  loading: PageLoadable,
});

const GewinnSpiel = Loadable({
  loader: () => import('../pages/GewinnSpiel'),
  loading: PageLoadable,
});

const BackformUmrechner = Loadable({
  loader: () => import('../pages/BackformUmrechnerPage'),
  loading: PageLoadable,
});

const BaseDoughPage = Loadable({
  loader: () => import('../pages/BaseDoughPage'),
  loading: PageLoadable,
});

// const PAGES_TO_PRESERVE_SCROLL_POS_ON = new Set(['/', '/rezepte', '/suche']);
const BACK_TO_TOP_BTN_THRESHOLD_PX = 1200;

export default class AppView extends Component {
  state = {
    showBackToTop: 0,
  }

  onResize = debounce(() => {
    const { setViewportWidth } = this.props;
    setViewportWidth(window.innerWidth);
  }, 250)

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const currY = getScrollY();
      const showBackToTop = currY > BACK_TO_TOP_BTN_THRESHOLD_PX;
      if (showBackToTop !== this.state.showBackToTop) {
        this.setState({ showBackToTop });
      }
    }, { passive: true });
    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    // const {
    //   setPreviousPagePathname,
    //   setCurrentPagePathname
    // } = this.props

    // if (prevProps.location.pathname !== this.props.location.pathname) {
    //   setPreviousPagePathname(prevProps.location.pathname)
    //   setCurrentPagePathname(this.props.location.pathname)
    // }

    let classNamePathName = this.props.location.pathname;
    classNamePathName = classNamePathName.replace(/\//g, '-');
    if (classNamePathName.startsWith('-')) {
      classNamePathName = classNamePathName.substring(1);
    }

    document.body.setAttribute('data-active-page', classNamePathName);

    // if (this.props.location.pathname !== prevProps.location.pathname) {
    //   if (PAGES_TO_PRESERVE_SCROLL_POS_ON.has(this.props.location.pathname)) {
    //     if (
    //       prevProps.location.pathname.startsWith('/rezept/') ||
    //       prevProps.location.pathname.startsWith('/artikel/') ||
    //       prevProps.location.pathname.startsWith('/forum/')
    //     ) {
    //       const newQueryParams = new URLSearchParams(prevProps.location.search)
    //       newQueryParams.delete('tab')
    //       replace(`${window.location.pathname}?${newQueryParams.toString()}`)
    //     } else {
    //       setCachedTeasers()
    //       const newQueryParams = new URLSearchParams(window.location.search)
    //       newQueryParams.delete('scrollOffset')
    //       newQueryParams.delete('listPageOffset')
    //       newQueryParams.delete('listPageIdx')
    //       replace(`${window.location.pathname}?${newQueryParams.toString()}`)
    //     }
    //   }
    // }
  }

  render() {
    const {
      showBackToTop,
    } = this.state;

    return (
        <div
          className="App"
        >
          <AppGlobalHookLoader />
          <div
            className={cx('App_go-back-to-top', {
              visible: showBackToTop,
            })}
            onClick={() => {
              window.scroll({
                top: 0,
                behavior: 'smooth',
              });
            }}
          />

          <SignInUpOverlay />
          <RequestNewPasswordOverlay />
          <Header />
          <CommentOverlay />
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/rezepte/:filter?" element={<RecipesPage />} />
            <Route path="/rezept/:slug/" element={<RecipePage />} />

            {/* If you need to redirect */}
            <Route path="/aktion" element={<Navigate to="/aktuelles" replace />} />

            <Route path="/aktuelles" element={<OverviewPage />} />
            <Route path="/backtipps" element={<OverviewPage />} />
            <Route path="/dekorieren-und-verzieren" element={<OverviewPage />} />
            <Route path="/forum" element={<ChannelOverviewPage />} />

            <Route path="/forum/:slug/" element={<ChannelPage />} />
            <Route path="/artikel/:slug/" element={<ArticlePage />} />
            <Route path="/forum/:channelSlug/beitrag/:slug/" element={<ForumThreadPage />} />

            <Route path="/seite/:slug/" element={<ContentPage />} />

            <Route path="/benutzer/:userID/" element={<UserPage />} />

            <Route path="/mein-profil" element={<MyProfilePage />} />
            <Route path="/meine-rezepte" element={<MyRecipesPage />} />
            <Route path="/meine-favoriten" element={<MyFavsPage />} />
            <Route path="/meine-nachrichten" element={<MyNotificationsPage />} />

            <Route path="/upload" element={<UploadPage />} />
            <Route path="/suche" element={<SearchPage />} />

            <Route path="/register" element={<HomePage />} />
            <Route path="/verify" element={<VerifyPage />} />

            <Route path="/cm/:filter?" element={<CmNotificationsPage />} />

            <Route path="/abmelden" element={<SignOffPage />} />

            <Route path="/newsletter" element={<NewsletterPage />} />
            <Route path="/newslettersucess" element={<NewsletterSuccessPage />} />

            <Route path="/memospiel-gewinner" element={<MemoryGameWinnersPage />} />

            <Route path="/analytics" element={<Analytics />} />
            <Route path="/suessegruesse" element={<SuesseGruessePage />} />
            <Route path="/selbstgebacken" element={<SelbstGebacken />} />
            <Route path="/selbstgebacken/rezept/:slug/" element={<RecipePageSebstgebacken />} />

            <Route path="/aktion" element={<GewinnSpiel />} />
            <Route path="/backformumrechner" element={<BackformUmrechner />} />

            <Route path={ GRUNDTEIGE_BASE_ROUTE } element={<BaseDoughPage />} />
            <Route
              path={`${GRUNDTEIGE_BASE_ROUTE}/:slug/`}
              element={<BaseDoughPage key={window.location.pathname} />}
            />
            <Route path="/lebeliebebacke" element={<ActivationCampaign />} />

            {/* Handling not found routes */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          <Footer />
        </div>
    );
  }
}
