import React from 'react';
import './Styles.css';

function RecipeDifficulty({ difficulty }) {
  if (!difficulty) {
    return <div />;
  }
  let orange = true;
  if (difficulty.name === 'einfach') {
    orange = false;
  }
  return (
    <div className="RecipeDifficulty" data-orange={orange}>
      {difficulty.name}
    </div>
  );
}

export default RecipeDifficulty;
