import React from 'react';
import classNames from 'classnames';
import './Styles.css';

const Headline = ({
  className,
  children,
  style,
  level,
  isSmall,
  isBig,
  isReallyBig,
  shouldBreak = true,
}) => {
  className = classNames(
    className,
    isSmall ? 'is-small-headline' : '',
    isBig ? 'is-big-headline' : '',
    isReallyBig ? 'is-really-big-headline' : '',
    shouldBreak ? 'dont-break-out' : '',
    'Headline',
  );
  switch (level) {
    case 1:
      return <h1
        className={className}
        style={style}
      >
        {children}
      </h1>;
    case 3:
      return <h3
        className={className}
        style={style}
      >
        {children}
      </h3>;
    default:
      return <h2
        className={className}
        style={style}
      >
        {children}
      </h2>;
  }
};
export default Headline;
