// actions constants

import API from '../../../tools/API';
import { xs, fetchWpV2Data, fetchFloursViewData, stripHtmlTags } from './utils';

export const SET_DOUGH = 'base-dough/SET_DOUGH';
export const SET_DOUGH_RECIPES = 'base-dough/SET_DOUGH_RECIPES';
export const SET_DOUGHS = 'base-dough/SET_DOUGHS';
export const START_DOUGH_LOADING = 'base-dough/START_DOUGH_LOADING';
export const START_DOUGH_LOADING_RECIPE = 'base-dough/START_DOUGH_LOADING_RECIPE';
export const DOUGH_LOADING_ERROR = 'base-dough/DOUGH_LOADING_ERROR';
export const SET_ALL_GRUNDTEIGE = 'base-dough/SET_ALL_GRUNDTEIGE';

// const WORDPRESS_ADMIN = process.env.WORDPRESS_ADMIN;
// const WORDPRESS_PASSWORD = process.env.WORDPRESS_PASSWORD;
// plain actions

export function setError(error) {
  return {
    type: DOUGH_LOADING_ERROR,
    error,
  };
}

export function setDoughs(payload) {
  return {
    type: SET_DOUGHS,
    payload,
  };
}

export function setDoughRecipies(payload) {
  return {
    type: SET_DOUGH_RECIPES,
    payload,
  };
}

export function startDoughLoading() {
  return { type: START_DOUGH_LOADING };
}

export function startDoughRecipeLoading() {
  return { type: START_DOUGH_LOADING_RECIPE };
}

export function setAllGrundteige(payload) {
  return {
    type: SET_ALL_GRUNDTEIGE,
    payload,
  };
}

// thunk actions

export function fetchDoughs(slug) {
  return async (dispatch, getState) => {
    const state = getState();
    if (!state) return;
    if (state.baseDoughPages.loading) return;

    dispatch(startDoughLoading());

    try {
      /* const tokenData = await API.getToken(WORDPRESS_ADMIN, WORDPRESS_PASSWORD);
      if (!tokenData.token) {
        throw new Error('Failed to fetch the JWT token');
      } */
      const urlParams = new URLSearchParams(window.location.search);
      const fetchDraft = urlParams.get('author') === 'true';

      let dataDraft = [];
      if (fetchDraft) {
        dataDraft = await API.constructedFetch('wp/v2/grundteige?acf_format=standard&status=draft', true);
      }
      const dataPublished = await API.constructedFetch(`wp/v2/grundteige?slug=${slug}&acf_format=standard&status=publish`, true);
      const data = [...dataDraft, ...dataPublished];
      const doughs = await Promise.all(data.map(async (page) => {
        const flourIds = xs(page, 'acf.mehlempfehlungen_wrapper.mehlempfelungen', []).map(flour => flour && flour.ID).filter(f => !!f);
        const flours = await fetchFloursViewData(flourIds);

        const dough = {
          id: xs(page, 'id'),
          slug: xs(page, 'slug'),
          title: xs(page, 'title.rendered'),
          pageTitle: xs(page, 'acf.seo_angaben.page_title') || `Grundteig: ${xs(page, 'acf.name_des_grundteiges')}`,
          pageKeywords: xs(page, 'acf.seo_angaben.keywords'),
          pageDescription: xs(page, 'acf.seo_angaben.beschreibung'),
          keyVisual: { // 1
            src: xs(page, 'acf.headerbild.url'),
            alt: xs(page, 'acf.headerbild.alt'),
          },
          recipe: { // 2
            title: xs(page, 'acf.rezept_headline'),
            subtitle: xs(page, 'acf.name_des_grundteiges'),
            description: xs(page, 'acf.rezepttext'),
            plainDescription: stripHtmlTags(xs(page, 'acf.rezepttext')),
            recipeIngredient: xs(page, 'acf.zutatenliste', []).map(({ zutat }) => zutat),

            // used for og:title, og:image and structured data
            seoTitle: `${xs(page, 'acf.rezept_headline')} - ${xs(page, 'acf.name_des_grundteiges')}`,
            image: {
              src: xs(page, 'acf.recipe_image.url') || xs(page, 'acf.headerbild.url'),
              alt: xs(page, 'acf.recipe_image.alt') || xs(page, 'acf.name_des_grundteiges'),
            },
          },
          video: { // 3
            title: xs(page, 'acf.youtubevideo.video_headline'),
            description: xs(page, 'acf.youtubevideo.videobeschreibung_html'),
            videoId: xs(page, 'acf.youtubevideo.youtubeid'),
          },
          recommendedFlours: { // 4
            title: 'Mehlempfehlungen',
            text: xs(page, 'acf.mehlempfehlungen_wrapper.mehlempfehlungen_text'),
            flours: flours.filter(f => !!f),
          },
          quickTipps: { // 5
            title: 'Quick Tipps',
            tipps: xs(page, 'acf.quicktipps', []).map(({ tipp }) => tipp),
          },
          campaigns: { // 6
            display: xs(page, 'acf.gewinnspiele__aktionen.display'),
            title: xs(page, 'acf.gewinnspiele__aktionen.gsheadline'),
            image1: {
              src: xs(page, 'acf.gewinnspiele__aktionen.bild_01.url'),
              alt: xs(page, 'acf.gewinnspiele__aktionen.bild_01.alt'),
            },
            image2: {
              src: xs(page, 'acf.gewinnspiele__aktionen.bild_02.url'),
              alt: xs(page, 'acf.gewinnspiele__aktionen.bild_02.alt'),
            },
            subtitle: xs(page, 'acf.gewinnspiele__aktionen.header'),
            description: xs(page, 'acf.gewinnspiele__aktionen.text'),
          },
          variations: { // 7
            title: xs(page, 'acf.variations.headline'),
            description: xs(page, 'acf.variations.description'),
            searchHint: 'Noch nicht fündig geworden? Finde weitere Inspirationen in unserer Suche!',
            moreButtonText: xs(page, 'acf.variations.search_button'),
            moreButtonLink: xs(page, 'acf.variations.search_button_link'),
          },
          tools: { // 8
            title: xs(page, 'acf.tools.headline', 'Materialkunde'),
            items: xs(page, 'acf.tools.slider_materialkunde', [])
              .filter(d => !!d && !!d.bild && !!d.bild.url && !!d.text)
              .map(d => ({ src: d.bild.url, description: d.text })),
          },
        };
        return dough;
      }));

      dispatch(setDoughs(doughs));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function fetchAllGrundteige() {
  return async (dispatch) => {
    try {
      const data = await API.constructedFetch('wp/v2/grundteige?acf_format=standard', true);
      dispatch(setAllGrundteige(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function fetchDoughsRecipes(slug) {
  return async (dispatch, getState) => {
    const state = getState();
    if (!state) return;
    if (state.baseDoughPages.recipeLoading) return;

    dispatch(startDoughRecipeLoading());

    try {
      const dataPublished = await API.constructedFetch(`wp/v2/grundteige?slug=${slug}&acf_format=standard&status=publish`, true);
      const data = [...dataPublished];
      const recipie = await Promise.all(data.map(async (page) => {
        const rids = xs(page, 'acf.variations.recipes', []).map(r => r.ID).filter(i => !!i);
        const recipes = await fetchWpV2Data(`rezept?include=${rids.join(',')}`, []);
        return recipes;
      }));

      dispatch(setDoughRecipies(recipie));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
