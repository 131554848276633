import React, { Component } from 'react';
import cx from 'classnames';

import './Styles.css';

class FormTextarea extends Component {
  render() {
    const { className, rows = 5, ...newProps } = this.props;
    return (
      <textarea
        rows={rows}
        className={cx('FormTextarea', className || '')}
        {...newProps}
      />
    );
  }
}

export default FormTextarea;
